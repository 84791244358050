// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HOC's or other ABSTRACTS (e.g. Higher Order Functions (HOF), Utils, constants, etc.)
import { jumbotronDataPageSystemintegration } from '../components/Jumbotron/__mockData__jumbotron';
import { infoBoxDataPageSystemintegration } from '../components/InfoBox/__mockData__infoBox';
// import PageSystemintegration from '../components/InfoBoxThreeColumn/__mockData__infoBoxThreeColumn';
// import ThreeColumnMockData from '../components/InfoBoxThreeColumn';

// //////////////////////////////
// IMPORT COMPONENTS
import LayoutOne from '../components/LayoutOne';
import Jumbotron from '../components/Jumbotron';
import GridRender from '../components/GridRender';
import GridIcons from '../components/GridIcons';
import InfoBox from '../components/InfoBox';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

function PageSystemintegration() {
  return (
    <LayoutOne>
      <Jumbotron data={jumbotronDataPageSystemintegration} />
      <GridRender
        leftContent="<h2>Was ist ein CRM?</h2> <h4>Customer-Relationship-Management soll Sie dabei unterstützen Ihre Kundenbeziehungen nachhaltig zu verbessern. Es handelt sich um einen Unternehmensansatz, der darauf abzielt, Ihre Geschäftsprozesse zu optimieren und Ihre Kunden langfristig zu binden. </h4>"
        leftCss="grid__column--43 grid__right-margin--2"
        rightContent="<p>In Unternehmen werden Kundendaten oft in den verschiedensten Abteilungen gesammelt, das heißt nicht alle Mitarbeiter arbeiten in demselben System. So können wichtige Kundeninformationen verloren gehen oder nicht für alle relevanten Abteilungen ersichtlich sein. Dies ändert sich mit dem Einsatz eines zentralisiertem CRM Systems, welches genau dieses Problem auffasst. Ein CRM System bietet eine Anwendung, die diese Daten speichert, auswertet und eine umfangreiche Sicht auf jeden Kunden bietet. Somit können Unternehmensprozesse digitalisiert werden.</p>"
        rightCss="grid__column--53 grid__left-margin--2"
      />
      <InfoBox data={infoBoxDataPageSystemintegration} />
      <GridRender
        gray
        leftContent="<h2>Mittel- und langfristig Kosten senken und den Betrieb stärken</h2> <p><strong>Durch den Einsatz eines CRM Systems wird eine langfristige Prozessoptimierung verfolgt, welche die digitale Transformation im Unternehmen vorantreibt.</strong></p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent="<p>Wir steigern Ihre Vertriebseffizienz, verbessern Ihren Kundenservice und sorgen für zufriedenere sowie produktivere Mitarbeiter. Darüber hinaus steigert Ihr Unternehmen die Wettbewerbsfähigkeit und senkt die Kosten im Unternehmen.</p>"
        rightCss="grid__column--48 grid__left-margin--2"
      />
      <GridRender
        leftContent="<h4>Über uns</h4> <p>Wir sind digitale Experten. Seit 2013 entwickeln wir für mittelständische Unternehmen konzeptionell und technisch innovative Digitalstrukturen. Diese sorgen für produktives, präzises und wettbewerbsfähiges Arbeiten Ihres Unternehmens in der Zukunft.</p> <p>Wir beraten Sie ganzheitlich in Ihrer IT-Strategie und kreieren individuelle Software und Webplattformen mit dem Nutzer im Fokus (UX). Zudem integrieren und erweitern wir CRM Systeme, welche von unserer exzellenten hauseigenen IT-Taskforce umgesetzt werden. Mit der vorhandenen Erfahrung analysieren wir Ihre Herausforderung und kümmern uns anschließend um alle Grundlagen sowie die Umsetzung, welche Sie für eine erfolgreiche, digitale Transformation brauchen.</p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent={<GridIcons />}
        rightCss="grid__column--48 grid__left-margin--2"
      />
      {/* <ThreeColumnMockData data={infoBoxThreeColumnMockData} /> */}
    </LayoutOne>
  );
}

// PageSystemintegration.propTypes = {

// };

// PageSystemintegration.defaultProps = {

// };

export default PageSystemintegration;
