/* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './InfoBoxThreeColumn.module.scss';

function InfoBoxThreeColumn({ data }) {
  return (
    <section className={`${style['info-box-three-column']}`}>
      <div className={`container ${style['info-box-three-column__wrapper']}`}>
        <h2>{data[0].mainTitle}</h2>

        <div className={`${style['info-box-three-column__three-columns']}`}>
          {data.map(columnData => (
            <div className={`${style['info-box-three-column__column']}`} key={columnData.mainTitle}>
              <h3>{columnData.title}</h3>

              <p>{columnData.content}</p>

              <Buttons
                href={`${columnData.button.url}`}
                className="button__one"
              >
                {columnData.button.name}
              </Buttons>
            </div>
          ))}
          {/* info-box-three-column__column */}
        </div>
        {/* info-box-three-column__three-columns */}
      </div>
      {/* info-box-three-column__wrapper */}
    </section>
  );
}

// InfoBoxThreeColumn.propTypes = {

// };

// InfoBoxThreeColumn.defaultProps = {

// };

export default InfoBoxThreeColumn;
