// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HOC's or other ABSTRACTS (e.g. Higher Order Functions (HOF), Utils, constants, etc.)
import { jumbotronDataPageITStrategieberatung } from '../components/Jumbotron/__mockData__jumbotron';
import { infoBoxDataPageITStrategieberatung } from '../components/InfoBox/__mockData__infoBox';
// import PageITStrategieberatung from '../components/InfoBoxThreeColumn/__mockData__infoBoxThreeColumn';
// import ThreeColumnMockData from '../components/InfoBoxThreeColumn';

// //////////////////////////////
// IMPORT COMPONENTS
import LayoutOne from '../components/LayoutOne';
import Jumbotron from '../components/Jumbotron';
import GridRender from '../components/GridRender';
import GridIcons from '../components/GridIcons';
import InfoBox from '../components/InfoBox';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

function PageITStrategieberatung() {
  return (
    <LayoutOne>
      <Jumbotron data={jumbotronDataPageITStrategieberatung} />
      <GridRender
        leftContent="<h2>Welche Aufgaben haben IT-Berater?</h2> <h4>Wir als professionelle  IT-Berater heben IT-Lösungen von externen Unternehmen auf ein ganz neues Level. Neben der Konzeption und der Neueinführung von Informationstechnologien, setzen wir bei der IT-Beratung auch Weiterentwicklungen sowie Rationalisierungen zielgerichtet um.</h4>"
        leftCss="grid__column--53 grid__right-margin--2"
        rightContent="<p>Dazu findet zunächst eine tiefgründige Analyse der Ressourcen und Anwendungen im IT-Bereich statt. Diese ermöglicht die Ermittlung sowie die anschließende Durchführung  der individuell besten Lösung im Zuge der persönlichen IT-Strategie. Durch rationalisierte, umgestaltete und weiterentwickelte Geschäftsmodelle, mithilfe von passenden IT-Lösungen, können wir für Sie einen enormen Wettbewerbsvorteil aufbauen.</p>"
        rightCss="grid__column--43 grid__left-margin--2"
      />
      <InfoBox data={infoBoxDataPageITStrategieberatung} />
      <GridRender
        gray
        leftContent="<h2>Sichern eines nachhaltigen Wettbewerbsvorteils</h2> <p><strong>Für Sie führen wir detaillierte und ganzheitliche IT-Analysen im Zuge unserer IT-Beratung durch. Wir übernehmen neben  der IT-Strategie sowie den Abläufen und Technologien auch die Unternehmensstrategie sowie die Leitung samt jeglicher Prozesse, um Sie und Ihr Team zu entlasten. Der IT-Bereich ist eng mit zahlreichen weiteren Geschäftsbereichen, wie dem Vertrieb und  dem Marketing, verknüpft. Dies macht sich auch bei der Bestimmung der Ziele in den Bereichen Portfolio, Führung, Infrastruktur, Architektur sowie Strategie und Programm-Management-Office bemerkbar.</strong></p>"
        leftCss="grid__column--53 grid__right-margin--2"
        rightContent="<p>Darüber hinaus führen wir für Ihre individuelle IT-Strategie eine detaillierte Kosten-Nutzen-Analyse durch, um das Leistungsversprechen richtig bewerten zu können. Außerdem erstellen wir einen kurzfristigen und langfristigen Umsetzungsplan, der Ihnen den Weg vom Ist-Zustand bis zum Ziel zeigt. Unsere IT-Beratung ist so aufgebaut, dass Sie Ihre persönliche IT-Strategie auch nachvollziehen können.</p> <p>Aufgrund der transparenten Auflistung der Aufwände können Sie Entscheidungen zur Kostenreduzierung ohne jegliche negativen Faktoren treffen. Bei unserer IT-Beratung setzen wir bewährte Systeme, Beschleuniger und Methoden ein. Mit diesen werden wir Ihnen zahlreiche IT-Strategien und Möglichkeiten zur Kostenreduzierung aufzeigen, die den Erfolg Ihres Unternehmens positiv unterstützen und fördern.</p>"
        rightCss="grid__column--43 grid__left-margin--2"
      />
      <GridRender
        leftContent="<h4>Über uns</h4> <p>Wir sind digitale Experten. Seit 2013 entwickeln wir für mittelständische Unternehmen konzeptionell und technisch innovative Digitalstrukturen. Diese sorgen für produktives, präzises und wettbewerbsfähiges Arbeiten Ihres Unternehmens in der Zukunft.</p> <p>Wir beraten Sie ganzheitlich in Ihrer IT-Strategie und kreieren individuelle Software und Webplattformen mit dem Nutzer im Fokus (UX). Zudem integrieren und erweitern wir CRM Systeme, welche von unserer exzellenten hauseigenen IT-Taskforce umgesetzt werden. Mit der vorhandenen Erfahrung analysieren wir Ihre Herausforderung und kümmern uns anschließend um alle Grundlagen sowie die Umsetzung, welche Sie für eine erfolgreiche, digitale Transformation brauchen.</p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent={<GridIcons />}
        rightCss="grid__column--48 grid__left-margin--2"
      />
      {/* <ThreeColumnMockData data={infoBoxThreeColumnMockData} /> */}
    </LayoutOne>
  );
}

// PageITStrategieberatung.propTypes = {

// };

// PageITStrategieberatung.defaultProps = {

// };

export default PageITStrategieberatung;
