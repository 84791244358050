import image5 from '../../images/jumbotron/jumbotron__image--5.jpg';
import image6 from '../../images/jumbotron/jumbotron__image--6.jpg';
import image9 from '../../images/jumbotron/jumbotron__image--9.jpg';
import image10 from '../../images/jumbotron/jumbotron__image--10.jpg';
import image11 from '../../images/jumbotron/jumbotron__image--11.jpg';

const jumbotronMockData = [
  {
    title:
      '<h1>Wir sind Ihre Digitaleagentur im Herzen Münchens für <span class="highlight">individuelle Digitaleprojekte</span></h1>',
    image: image10,
    buttons: [
      {
        name: 'Beratung & Prozessanalyse (Anforderungsanalyse)',
        url: '/leistungen/individuelle-digitalprojekte',
      },
      {
        name: 'IoT Systeme',
        url: '/leistungen/individuelle-digitalprojekte',
      },
      {
        name: 'IT-Projektmanagement',
        url: '/leistungen/it-strategieberatung',
      },
      {
        name: 'IT-Architektur',
        url: '/leistungen/it-strategieberatung',
      },
      {
        name: 'Sicherheit & Compliance',
        url: '/leistungen/it-strategieberatung',
      },
      {
        name: 'Cloud-Systeme',
        url: '/leistungen/it-strategieberatung',
      },
    ],
  },
];

export default jumbotronMockData;

// //////////////////////////////
// PageIndividuelleDigitalprojekte
// //////////////////////////////

export const jumbotronDataPageIndividuelleDigitalprojekte = [
  {
    title:
      '<h1>Wir sind Ihre Digitaleagentur für <br><span class="highlight">individuelle Digitaleprojekte</span> </h1>',
    image: image6,
    // buttons: [
    //   {
    //     name: 'E-commmerce E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce aldkasl lkajsdlkasjd ',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   }
    // ],
  },
];

// //////////////////////////////
// PageSystemintegration
// //////////////////////////////

export const jumbotronDataPageSystemintegration = [
  {
    title:
      '<h1>Wir sind Ihre Digitaleagentur für <br><span class="highlight">CRM-Beratung und -Integration</span> </h1>',
    image: image9,
    // buttons: [
    //   {
    //     name: 'E-commmerce E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce aldkasl lkajsdlkasjd ',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   }
    // ],
  },
];

// //////////////////////////////
// PageITStrategieberatung
// //////////////////////////////

export const jumbotronDataPageITStrategieberatung = [
  {
    title:
      '<h1>Wir sind Ihre neue Agentur für <br><span class="highlight">digitale Transformation & IT-Strategie</span> </h1>',
    image: image5,
    // buttons: [
    //   {
    //     name: 'E-commmerce E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce aldkasl lkajsdlkasjd ',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   }
    // ],
  },
];

// //////////////////////////////
// PageLeistungen
// //////////////////////////////

export const jumbotronDataPageLeistungen = [
  {
    title:
      '<h1>Unsere Leistungen &ndash; <br><span class="highlight">von der Analyse bis zur perfekten Umsetzung</span> </h1>',
    image: image11,
    // buttons: [
    //   {
    //     name: 'E-commmerce E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce aldkasl lkajsdlkasjd ',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   },
    //   {
    //     name: 'E-commmerce',
    //     url: '/leistungen/individuelle-digitalprojekte',
    //   }
    // ],
  },
];
