// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
// import style from './LayoutOne.module.scss';

function LayoutOne({ children }) {
  return (
    <Fragment>
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
}

LayoutOne.propTypes = {
  children: PropTypes.node.isRequired,
};

// LayoutOne.defaultProps = {

// };

export default LayoutOne;
