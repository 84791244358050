// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
// import Buttons from '../Buttons';
import Header from '../Header';
import Footer from '../Footer';
import { IconLogo } from '../Icons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_kontakt_optimized.jpg';

// //////////////////////////////
// IMPORT STYLES
import style from './Kontakt.module.scss'; // eslint-disable-line

function Kontakt({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                Wir freuen uns
              </span>{' '}
              <span className="highlight">Sie kennenzulernen!</span>
            </h1>
            <div className="two-columns">
              <p>
                <IconLogo title="Impact Horizon" className="logo-editorz" />
                <br />
                Hopfenstrasse 8 <br />
                80335 München
                <br />
                <br />
                <strong>T:</strong> +49 89 235 13504 - 0
                <br />
                <strong>F:</strong> +49 89 235 13504 - 8
              </p>
              <div>
                <a
                  href="mailto:info@impacthorizon.de"
                  className="button__one--dark"
                >
                  Kontakt
                </a>
              </div>
            </div>
            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Wir freuen uns
              <span className="highlight">Sie kennenzulernen!</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

Kontakt.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

Kontakt.defaultProps = {
  reverse: true,
  image: IMAGE,
};

export default Kontakt;
