// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HOC's or other ABSTRACTS (e.g. Higher Order Functions (HOF), Utils, constants, etc.)
import { jumbotronDataPageIndividuelleDigitalprojekte } from '../components/Jumbotron/__mockData__jumbotron';
import { infoBoxDataPageIndividuelleDigitalprojekte } from '../components/InfoBox/__mockData__infoBox';
// import PageIndividuelleDigitalprojekte from '../components/InfoBoxThreeColumn/__mockData__infoBoxThreeColumn';
// import ThreeColumnMockData from '../components/InfoBoxThreeColumn';

// //////////////////////////////
// IMPORT COMPONENTS
import LayoutOne from '../components/LayoutOne';
import Jumbotron from '../components/Jumbotron';
import GridRender from '../components/GridRender';
import GridIcons from '../components/GridIcons';
import InfoBox from '../components/InfoBox';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

function PageIndividuelleDigitalprojekte() {
  return (
    <LayoutOne>
      <Jumbotron data={jumbotronDataPageIndividuelleDigitalprojekte} />
      <GridRender
        leftContent="<h2>Individuelle Digitalprojekte</h2> <h4>Als Digitalagentur mit vielen Jahren Erfahrung bieten wir individuelle Softwareentwicklung an und machen Ihr Unternehmen fit für die Zukunft. Mithilfe von Methoden und Techniken, wie Agile, Scrum und Design Thinking, entwickeln wir effiziente und zukunftssichere Systeme. Dabei sind wir Ihre Partner von der Konzeption, über Kreation, bis hin zur Programmierung und der Umsetzung digitaler Lösungen sowie der stetigen Optimierung.</h4>"
        leftCss="grid__column--53 grid__right-margin--2"
        rightContent="<p>Ihre digitale Herausforderung mit Ihnen zu meistern ist unsere Leidenschaft. Ob Problemdefinition, Strategieentwicklung, Ideenfindung oder Umsetzung bereits definierter Pflichtenhefte – wir beginnen dort, wo es für Sie am sinnvollsten ist. Als Digitalagentur verfügen wir nicht nur über beste Softwareentwickler, sondern sind auch B2B Experten. Dabei sind wir auf die Bedürfnisse mittelständischer Unternehmen spezialisiert. Wir wissen genau, dass es auf die Nähe zum Kunden sowie dessen Unternehmen ankommt. Wir verfolgen das Ziel, Ihr Marketing und Ihren Vertrieb zu stärken. Dazu optimieren wir durch Einsatz unserer Expertise zahlreiche Prozesse und sichern Ihren Erfolg.</p>"
        rightCss="grid__column--43 grid__left-margin--2"
      />
      <InfoBox data={infoBoxDataPageIndividuelleDigitalprojekte} />
      <GridRender
        gray
        leftContent="<h2>Stärkung von Marketing und Vertrieb sowie Prozessoptimierung</h2> <p><strong>Bei uns steht der Kunde stets im Mittelpunkt und unser Anspruch ist es, als Digitalagentur B2B Lösungen zu entwickeln, die Ihr Unternehmen passend erweitern. Jegliche Vertriebsprozessoptimierungen und Maßnahmen zur Marketing Automation sind durch Skalierbarkeit und Nachhaltigkeit zukunftssicher. Egal ob Corporate Website, Portal oder Progressive Web-App (PWA), unsere Softwareentwickler setzen auf modernste Technologien und Programmiersprachen.</strong></p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent="<p>Bei der Implementation von beispielsweise Marketing Automation in Ihrem Unternehmen sowie der Optimierung Ihres Vertriebs und Ihrer Prozesse setzen wir ausschließlich auf renommierte Software, um Industriestandards zu gewährleisten. Dazu zählen Magento, Shopware, WordPress, Typo3 und viele weitere Anwendungen. Durch unsere erfahrenen Softwareentwickler können wir Ihnen zudem auch individuelle Softwarelösungen für Marketing und Vertrieb anbieten und integrieren.</p><p>Zudem verfügen wir über ein In-House UX/UI Kreationsteam und sind daher auch im User Experience Design (UX Design) bestens aufgestellt. Unsere In-House Programmierabteilung “IT-Taskforce” schafft es binnen kürzester Zeit, passende und effektive Lösungen zu entwickeln.
        Sie sparen nicht nur Zeit, sondern auch Kosten durch unseren Einsatz der modernsten Arbeitsmethoden, wie Scrum und Design Thinking, in Ihrem Unternehmen. Dadurch fördern wir Ihren langfristigen Erfolg.
        </p>"
        rightCss="grid__column--48 grid__left-margin--2"
      />
      <GridRender
        leftContent="<h4>Über uns</h4> <p>Wir sind digitale Experten. Seit 2013 entwickeln wir für mittelständische Unternehmen konzeptionell und technisch innovative Digitalstrukturen. Diese sorgen für produktives, präzises und wettbewerbsfähiges Arbeiten Ihres Unternehmens in der Zukunft.</p> <p>Wir beraten Sie ganzheitlich in Ihrer IT-Strategie und kreieren individuelle Software und Webplattformen mit dem Nutzer im Fokus (UX). Zudem integrieren und erweitern wir CRM Systeme, welche von unserer exzellenten hauseigenen IT-Taskforce umgesetzt werden. Mit der vorhandenen Erfahrung analysieren wir Ihre Herausforderung und kümmern uns anschließend um alle Grundlagen sowie die Umsetzung, welche Sie für eine erfolgreiche, digitale Transformation brauchen.</p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent={<GridIcons />}
        rightCss="grid__column--48 grid__left-margin--2"
      />
      {/* <ThreeColumnMockData data={infoBoxThreeColumnMockData} /> */}
    </LayoutOne>
  );
}

// PageIndividuelleDigitalprojekte.propTypes = {

// };

// PageIndividuelleDigitalprojekte.defaultProps = {

// };

export default PageIndividuelleDigitalprojekte;
