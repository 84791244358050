// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import NavigationMobile from '../NavigationMobile';
import { IconLogo } from '../Icons';
import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './Header.module.scss';

function Header({ dark }) {
  return (
    <Fragment>
      <header
        className={`${style['header--type-1']}  ${style['backdrop-blur']}`}
      >
        {/* ${style['header--type-2']} */}
        <div className={`container ${style['header__wrapper']}`}>
          {/* <div className={`${style['header__blurer']}`}> ddf</div> */}
          <section className={`${style['header__logo']}`}>
            <Link to="/">
              <IconLogo
                title="Impact Horizon"
                // className={`${style['header__logo__the-logo']}`}
              />
            </Link>
          </section>
          {/* header__logo */}
          <section className={`${style['header__right-side']}`}>
            {/* <nav>
            <ul className={`${style['nav__row']}`}>
              <li>
                <a href="#test">No dropdown</a>
              </li>
              <li>
                <Link
                  to="#test"
                  aria-expanded="false"
                  aria-haspopup="true"
                  className={`${style['nav__trigger']}`}
                >
                  One<span aria-hidden="true">&#x25be;</span>
                </Link>
                <ul
                  id="dropdown-1"
                  role="menu"
                  className={`${style['nav__dropdown']}`}
                >
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-1
                    </a>
                  </li>
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-2
                    </a>
                  </li>
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-3
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#test"
                  id="dropdown-2"
                  aria-expanded="false"
                  aria-haspopup="true"
                  className={`${style['nav__trigger']}`}
                >
                  Two<span aria-hidden="true">&#x25be;</span>
                </a>
                <ul
                  id="dropdown-2"
                  role="menu"
                  className={`${style['nav__dropdown']}`}
                >
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-1
                    </a>
                  </li>
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-2
                    </a>
                  </li>
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-3
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#test"
                  id="dropdown-3"
                  aria-expanded="false"
                  aria-haspopup="true"
                  className={`${style['nav__trigger']}`}
                >
                  Three<span aria-hidden="true">&#x25be;</span>
                </a>
                <ul
                  id="dropdown-3"
                  role="menu"
                  className={`${style['nav__dropdown']}`}
                >
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-1
                    </a>
                  </li>
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-2
                    </a>
                  </li>
                  <li className={`${style['nav__list']}`}>
                    <a
                      href="#test"
                      role="menuitem"
                      className={`${style['nav__link']}`}
                    >
                      Sub-3
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav> */}

            {/* <Route path="/impressum/datenschutz" component={ImpressumDatenschutz} /> */}

            <nav className={`${style['nav']}`}>
              <ul className={`${style['nav__first-level']}`}>
                <li
                  className={`${style['nav__first-level__button']} ${dark &&
                    style['nav__button--dark']}`}
                >
                  <span
                    className={`${style['nav__first-level__button--wrapper']}`}
                  >
                    <Link to="/agentur">Agentur{/* translate */}</Link>
                  </span>
                  <ul
                    className={`container ${style['nav__second-level']} ${
                      style['backdrop-blur']
                    }`}
                    aria-label="submenu"
                  >
                    <li className={`${style['nav__second-level__button']}`}>
                      <Link to="/agentur-herangehensweise">
                        Herangehensweise{/* translate */}
                      </Link>
                    </li>
                    <li className={`${style['nav__second-level__button']}`}>
                      <Link to="/agentur-taskforce">
                        IT-Taskforce{/* translate */}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${style['nav__first-level__button']} ${dark &&
                    style['nav__button--dark']}`}
                >
                  <span
                    className={`${style['nav__first-level__button--wrapper']}`}
                  >
                    <Link to="/leistungen">Leistungen{/* translate */}</Link>
                  </span>
                  <ul
                    className={`container ${style['nav__second-level']} ${
                      style['backdrop-blur']
                    }`}
                    aria-label="submenu"
                  >
                    <li className={`${style['nav__second-level__button']}`}>
                      <Link to="/leistungen/it-strategieberatung">
                        IT Strategieberatung{/* translate */}
                      </Link>
                    </li>
                    <li className={`${style['nav__second-level__button']}`}>
                      <Link to="/leistungen/individuelle-digitalprojekte">
                        Individuelle Digitalprojekte{/* translate */}
                      </Link>
                    </li>
                    <li className={`${style['nav__second-level__button']}`}>
                      <Link to="/leistungen/systemintegration">
                        Systemintegration{/* translate */}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${style['nav__first-level__button']} ${dark &&
                    style['nav__button--dark']}`}
                >
                  <span
                    className={`${style['nav__first-level__button--wrapper']}`}
                  >
                    <Link to="/referenzen">Case Studies{/* translate */}</Link>
                  </span>
                </li>
                <li
                  className={`${style['nav__first-level__button']} ${dark &&
                    style['nav__button--dark']}`}
                >
                  <span
                    className={`${style['nav__first-level__button--wrapper']}`}
                  >
                    <Link to="/erstanalyse">Erstanalyse{/* translate */}</Link>
                  </span>
                </li>
                <li
                  className={`${style['nav__first-level__button']} ${dark &&
                    style['nav__button--dark']}`}
                >
                  <span
                    className={`${style['nav__first-level__button--wrapper']}`}
                  >
                    <Link to="/karriere">Karriere{/* translate */}</Link>
                  </span>
                </li>
              </ul>
            </nav>

            <Buttons
              href="/kontakt"
              className={dark ? 'button__one--dark' : 'button__one'}
            >
              Kontakt
            </Buttons>
          </section>
          {/* header__right-side */}
        </div>
        {/* header__wrapper */}
      </header>
      <NavigationMobile />
    </Fragment>
  );
}

Header.propTypes = {
  dark: PropTypes.bool,
};

Header.defaultProps = {
  dark: true,
};

export default Header;
