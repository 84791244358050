/* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Jumbotron from './Jumbotron';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

class JumbotronComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 'Some Test State',
    };
  }

  render() {
    const { test } = this.state; // eslint-disable-line
    const { data } = this.props;
    return (
      <Fragment>
        {data.map(jumbos => (
          <Jumbotron data={jumbos} key={jumbos.title} />
        ))}
      </Fragment>
    );
  }
}

// const dataOjectShape = {

// }

// JumbotronComponent.propTypes = {
//   data: PropTypes.dataOjectShape.isRequired,
// };

// JumbotronComponent.defaultProps = {

// };

export default JumbotronComponent;
