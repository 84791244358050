// //////////////////////////////
// IMPORT MODULES
import React from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

// //////////////////////////////
// IMPORT OVERLAYS
// import imageOverlay from "../../images/_overlays/put-overlays-in-here.png";

function Icon({
  children,
  className,
  title,
  viewBoxDimensions,
  ariaLabelledby,
  ariaHidden,
  ...attributes
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBoxDimensions}
      aria-labelledby={ariaLabelledby} // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute
      aria-hidden={ariaHidden} // https://developer.paciellogroup.com/blog/2012/05/html5-accessibility-chops-hidden-and-aria-hidden/
      {...attributes}
    >
      <title id={title}>{title}</title>
      {children}
    </svg>
  );
}

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  viewBoxDimensions: PropTypes.string.isRequired,
  ariaLabelledby: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

Icon.defaultProps = {
  ariaHidden: false,
  ariaLabelledby: null,
};

export default Icon;
