// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import {
  IconHome,
  IconBarChart,
  IconPaperclip,
  IconChartUpTrend,
  IconContact,
  // IconBurger,
} from '../Icons';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './NavigationMobile.module.scss';

function NavigationMobile() {
  return (
    <section className={`${style['navigation-mobile']}`}>
      <Link to="/" className={`${style['navigation-mobile__button']}`}>
        <IconHome
          title="Home"
          className={`${style['navigation-mobile__button__icon']}`}
        />
        <h6>Home</h6>
      </Link>

      <Link
        to="/leistungen"
        className={`${style['navigation-mobile__button']}`}
      >
        <IconBarChart
          title="Leistungen"
          className={`${style['navigation-mobile__button__icon']}`}
        />
        <h6>Leistungen</h6>
      </Link>

      <Link
        to="/referenzen"
        className={`${style['navigation-mobile__button']}`}
      >
        <IconPaperclip
          title="Refernzen"
          className={`${style['navigation-mobile__button__icon']}`}
        />
        <h6>Case Studies</h6>
      </Link>

      <Link to="/karriere" className={`${style['navigation-mobile__button']}`}>
        <IconChartUpTrend
          title="Karriere"
          className={`${style['navigation-mobile__button__icon']}`}
        />
        <h6>Karriere</h6>
      </Link>

      <Link to="/kontakt" className={`${style['navigation-mobile__button']}`}>
        <IconContact
          title="Kontakt"
          className={`${style['navigation-mobile__button__icon']}`}
        />
        <h6>Kontakt</h6>
      </Link>

      {/* <button
        type="button"
        onClick={() => console.log('hello')} // eslint-disable-line
        className={`${style['navigation-mobile__button']}`}
      >
        <IconBurger
          title="Kontakt"
          className={`${style['navigation-mobile__button__icon']}`}
        />
        <h6>Mehr</h6>
      </button> */}
    </section>
  );
}

// NavigationMobile.propTypes = {

// };

// NavigationMobile.defaultProps = {

// };

export default NavigationMobile;
