// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
// import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_taskforce_optimized.jpg';

// //////////////////////////////
// IMPORT STYLES
import style from './AgenturTaskforce.module.scss'; // eslint-disable-line

function AgenturTaskforce({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              Unsere hauseigene
              <span className="highlight">IT-Taskforce</span>
            </h1>
            <h2>
              Unsere IT-Taskforce besteht aus Spezialisten für ERP und CRM
              Systemintegrationen, individuelle Softwareentwicklungen, Web- und
              Desktop-Anwendungen und Product Information Management-Systeme.
            </h2>

            <div className="two-columns">
              <p>
                Von Anfang an werden die Entwickler von unseren Beratern in Ihr
                Projekt eingebunden, um die nahtlose Umsetzung zu garantieren
                und Projektabläufe reibungslos zu gestalten. Außerdem steht
                Ihnen unsere Taskforce jederzeit bei technischen Fragen zur
                Verfügung. Mit diesen Prinzipien im Hintergrund erarbeiten wir
                mit Ihnen gemeinsam digitale Lösungen, die Ihr Unternehmen fit
                für neue Herausforderungen macht.
              </p>
            </div>

            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Unsere hauseigene
              <span className="highlight">IT-Taskforce</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

AgenturTaskforce.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

AgenturTaskforce.defaultProps = {
  reverse: true,
  image: IMAGE,
};

export default AgenturTaskforce;
