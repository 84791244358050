// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
// import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_workshop_optimized.jpg';
import PDF from '../Agentur/ih__agency_brochure_web.pdf';

// //////////////////////////////
// IMPORT STYLES
import style from './LeistungenErstanalyse.module.scss';

function LeistungenErstanalyse({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                Digitalisierungs-
              </span>{' '}
              <span className="highlight">Erstanalyse</span>
            </h1>
            <h2>Lernen Sie Ihr digitales Geschäftspotenzial kennen!</h2>
            <p>
              <strong>
                Alle reden von Digitalisierung – aber was heißt das für Ihr
                Unternehmen? Vor welchen digitalen Herausforderungen stehen Sie?
                Soll Ihre Digitalstruktur auf den neusten Stand gebracht werden?
                Oder wünschen Sie sich einen vernetzten Workflow und höhere
                Effizienz?
              </strong>
            </p>
            <div className="two-columns">
              <p>
                In unserer sich rasant wandelnden digitalen Welt verändern sich
                Kundenerwartungen und Geschäftsmodelle in atemberaubendem Tempo.
                Mittelständischen Unternehmen verlangt der digitale Wandel
                besonders viel ab. In einem Erstanalyse-Workshop zeigen wir
                Ihnen, wie Ihr Unternehmen in einer digitalisierten Welt jetzt
                und in Zukunft hochfunktional und wettbewerbsfähig arbeiten
                kann.{' '}
                <strong>
                  Impact Horizon ist Ihr Partner für digitale Strategie und
                  Umsetzung.
                </strong>
              </p>
            </div>
            <h2 className="two-columns">
              Jetzt Termin vereinbaren &nbsp;
              <strong>T: +49 89 235 13504-0</strong>
            </h2>
            <div className="special-p">
              <a
                href={PDF}
                target="_blank"
                rel="noopener noreferrer"
                className="button__one--dark"
              >
                Download Agenturbroschüre (PDF; 1,9 MB)
              </a>
            </div>
            <br />
            <div>
              <h2>Ihre unternehmerischen &amp; wirtschaftlichen Vorteile</h2>
              <span>
                <ul>
                  <li>Wettbewerbsfähigkeit ausbauen</li>
                  <li>Kosten in Ihrem Unternehmen senken</li>
                  <li>Schneller Return on Investment</li>
                  <li>Kundenerwartungen erfüllen und übertreffen</li>
                  <li>Zufriedenere &amp; produktivere Mitarbeiter</li>
                </ul>
              </span>
            </div>

            <div>
              <h2>Wann macht ein Workshop Sinn?</h2>
              <span>
                <ul>
                  <li>Wenn noch keine Digitalstrategie vorliegt</li>
                  <li>Bei instabilen oder langsamen Systemen</li>
                  <li>Wenn inkompatible Formate den Workflow bremsen</li>
                  <li>Bei drohendem Datenverlust oder Versionierungen</li>
                  <li>
                    Wenn Sie die Zusammenarbeit von Mitarbeitern und Abteilungen
                    verbessern möchten
                  </li>
                  <li>
                    Wenn Sie erfahren möchten wie eine Digitalisierung Ihrem
                    Unternehmen helfen kann
                  </li>
                </ul>
              </span>
            </div>

            <div>
              <h2>Inhalte des Erstanalyse-Workshops</h2>
              <span>
                <ul>
                  <li>IT-Problemanalyse</li>
                  <li>Chancen der Digitalisierung in Ihrem Unternehmen</li>
                  <li>Erarbeitung digitaler Lösungsansätze</li>
                </ul>
              </span>
            </div>

            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Digitalisierungs-
              <span className="highlight">Erstanalyse</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

LeistungenErstanalyse.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

LeistungenErstanalyse.defaultProps = {
  reverse: false,
  image: IMAGE,
};

export default LeistungenErstanalyse;
