// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_agency_optimized.jpg';
import PDF from './ih__agency_brochure_web.pdf';

// //////////////////////////////
// IMPORT STYLES
import style from './Agentur.module.scss';

function Agentur({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                Agentur
              </span>{' '}
              für
              <span className="highlight">digitale Systeme</span>
            </h1>
            <h2>
              Für mittelständische Unternehmen entwickeln wir konzeptionell und
              technisch innovative Digitalstrukturen, damit Ihr Unternehmen auch
              in Zukunft produktiv, präzise und wettbewerbsfähig arbeiten kann.
            </h2>
            <div className="two-columns">
              <p>
                Von der Analyse Ihrer digitalen Prozesse und
                Marktherausforderungen über die Strategieentwicklung bis zur
                Umsetzung in exzellentem Code – wir steigen da ein, wo Sie es
                wünschen. Unsere Produkte und Services werden speziell für Ihre
                Anforderungen konzipiert und präzise umgesetzt. Den hohen
                Qualitätsanspruch an alle von uns implementierten
                Softwaresysteme können wir nur dank unserer exzellenten{' '}
                <b>hauseigenen IT-Taskforce</b> erfüllen.
              </p>
            </div>

            <div className="special-p">
              <a
                href={PDF}
                target="_blank"
                rel="noopener noreferrer"
                className="button__one--dark"
              >
                Download Agenturbroschüre (PDF; 1,9 MB)
              </a>
            </div>
            <br />
            <br />
            <h2>
              „We keep our eyes on the horizon, not just on the bottom line.“
              <br />– Impact Horizon
            </h2>

            <h2>Von der Analyse bis zur perfekten Umsetzung</h2>
            <div className="two-columns">
              <span>
                <p>
                  Ihre digitale Herausforderung ist unsere Leidenschaft. Ob
                  Problemdefinition, Strategieentwicklung, Ideenfindung oder
                  Umsetzung bereits definierter Pflichtenhefte – wir beginnen
                  dort, wo Sie es möchten.
                </p>
                <div>
                  <Buttons href="/leistungen/" className="button__one--dark">
                    Unsere Leistungen
                  </Buttons>
                </div>
              </span>
            </div>
            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Von der
              <span className="highlight">Analyse</span>
              bis zur
              <span className="highlight">perfekten Umsetzung</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

Agentur.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

Agentur.defaultProps = {
  reverse: true,
  image: IMAGE,
};

export default Agentur;
