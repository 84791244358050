// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HOC's or other ABSTRACTS (e.g. Higher Order Functions (HOF), Utils, constants, etc.)
import { jumbotronDataPageLeistungen } from '../components/Jumbotron/__mockData__jumbotron';
import infoBoxThreeColumnMockData from '../components/InfoBoxThreeColumn/__mockData__infoBoxThreeColumn';

// //////////////////////////////
// IMPORT COMPONENTS
import LayoutOne from '../components/LayoutOne';
import Jumbotron from '../components/Jumbotron';
import GridRender from '../components/GridRender';
import GridIcons from '../components/GridIcons';
import ThreeColumnMockData from '../components/InfoBoxThreeColumn';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

function PageLeistungen() {
  return (
    <LayoutOne>
      <Jumbotron data={jumbotronDataPageLeistungen} />
      <GridRender
        leftContent="<h4>Wir sind Ihre Ansprech- und Sparringpartner für IT-Projekte, Systemintegration und individuelle digitale Entwicklung.</h4>"
        leftCss="grid__column--43 grid__right-margin--2"
        rightContent="<p>Ihre digitale Herausforderung ist unsere Leidenschaft. Ob Problemdefinition, Strategieentwicklung, Ideenfindung oder Umsetzung bereits definierter Pflichtenhefte – wir beginnen dort, wo Sie es möchten. Dabei sind wir auf die Bedürfnisse mittelständischer Unternehmen spezialisiert. Dank eines breiten Erfahrungshorizonts können wir uns in Ihre komplexen Prozesse hineindenken.</p>"
        rightCss="grid__column--53 grid__left-margin--2"
      />
      <ThreeColumnMockData data={infoBoxThreeColumnMockData} />
      <GridRender
        gray
        leftContent="<h3> Zwischen Kreation und Softwareentwicklung </h3> <p>Unser Team aus Beratern, Kreativen und unserer starken IT-Taskforce arbeitet täglich Hand in Hand. So werden innovative Lösungen geschaffen -- für Sie!</p><h3> Komplettlösungen </h3> <p>Wichtig ist, dass alle konzeptionellen und technischen Komponenten auf Sie und Ihre Produkte oder Dienstleistungen perfekt abgestimmt sind. Wir lieben und leben, was wir tun!</p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent="<h3>Langjährige Partnerschaften</h3><p>Wir sind nicht nur eine On-Demand Digitalagentur und IT-Feuerwehr, sondern werden in vertrauensvoller Zusammenarbeit dank konsistenter Qualität auch gern Ihr fester strategischer IT-Partner.</p>"
        rightCss="grid__column--48 grid__left-margin--2"
      />
      <GridRender
        leftContent="<h4>Über uns</h4> <p>Wir sind digitale Experten. Seit 2013 entwickeln wir für mittelständische Unternehmen konzeptionell und technisch innovative Digitalstrukturen. Diese sorgen für produktives, präzises und wettbewerbsfähiges Arbeiten Ihres Unternehmens in der Zukunft.</p> <p>Wir beraten Sie ganzheitlich in Ihrer IT-Strategie und kreieren individuelle Software und Webplattformen mit dem Nutzer im Fokus (UX). Zudem integrieren und erweitern wir CRM Systeme, welche von unserer exzellenten hauseigenen IT-Taskforce umgesetzt werden. Mit der vorhandenen Erfahrung analysieren wir Ihre Herausforderung und kümmern uns anschließend um alle Grundlagen sowie die Umsetzung, welche Sie für eine erfolgreiche, digitale Transformation brauchen.</p>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent={<GridIcons />}
        rightCss="grid__column--48 grid__left-margin--2"
      />
    </LayoutOne>
  );
}

// PageLeistungen.propTypes = {

// };

// PageLeistungen.defaultProps = {

// };

export default PageLeistungen;
