// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import {
  LogoClientCisco,
  LogoClientFujitsu,
  LogoClientVodafone,
  LogoClientFsecure,
  LogoClientIbm,
  LogoClient7tv,
  // LogoClientMaxdome,
  LogoClientFraunhofer,
} from '../LogosClient';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './GridIcons.module.scss';

function GridIcons() {
  return (
    <aside className={`${style['grid-icons']}`}>
      {/* <h3 className={`${style['grid-icons__heading']}`}>heading</h3> */}
      <h3>Zufriedene Kunden</h3>
      <div className={`${style['grid-icons__grid']}`}>
        <LogoClientCisco title="Ciso" />
        <LogoClientFujitsu title="Fujitsu Germany" />
        {/* <LogoClientMaxdome title="Maxdome" /> */}
        <LogoClientVodafone title="Vodafone" />
        <LogoClientFsecure title="F-Secure" />
        <LogoClientIbm title="IBM" />
        <LogoClient7tv title="7TV GmbH" />
        <LogoClientFraunhofer title="Fraunhofer" />
      </div>
    </aside>
  );
}

// GridIcons.propTypes = {

// };

// GridIcons.defaultProps = {

// };

export default GridIcons;
