// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS
import ScrollToTop from '../../services/ScrollToTop';

// //////////////////////////////
// IMPORT COMPONENTS
// import Homepage from '../../components/Homepage';
import Agentur from '../../components/Agentur';
import AgenturHerangehensweise from '../../components/AgenturHerangehensweise';
import AgenturTaskforce from '../../components/AgenturTaskforce';
import ImpressumDatenschutz from '../../components/ImpressumDatenschutz';
import Karriere from '../../components/Karriere';
import LeistungenErstanalyse from '../../components/LeistungenErstanalyse';
import Referenzen from '../../components/Referenzen';
import Kontakt from '../../components/Kontakt';

// //////////////////////////////
// IMPORT PAGES
import PageHome from '../PageHome';
// Leistungen
import PageLeistungen from '../PageLeistungen';
// Leistungen > Leistungen Sub-pages:
import PageIndividuelleDigitalprojekte from '../PageIndividuelleDigitalprojekte';
import PageSystemintegration from '../PageSystemintegration';
import PageITStrategieberatung from '../PageITStrategieberatung';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={PageHome} />
          {/* //////// Leistungen Pages //////// */}
          <Route exact path="/leistungen" component={PageLeistungen} />
          <Route
            path="/leistungen/it-strategieberatung"
            component={PageITStrategieberatung}
          />
          <Route
            path="/leistungen/individuelle-digitalprojekte"
            component={PageIndividuelleDigitalprojekte}
          />
          <Route
            path="/leistungen/systemintegration"
            component={PageSystemintegration}
          />
          {/* //////// Old Page //////// */}
          <Route path="/agentur" component={Agentur} />
          <Route path="/agentur" component={Agentur} />
          <Route
            path="/agentur-herangehensweise"
            component={AgenturHerangehensweise}
          />
          <Route path="/agentur-taskforce" component={AgenturTaskforce} />
          <Route
            path="/impressum-datenschutz"
            component={ImpressumDatenschutz}
          />
          <Route path="/karriere" component={Karriere} />
          <Route path="/erstanalyse" component={LeistungenErstanalyse} />
          <Route path="/erstanalyse/" component={LeistungenErstanalyse} />
          <Route path="/referenzen" component={Referenzen} />
          <Route path="/kontakt" component={Kontakt} />
          <Route component={LeistungenErstanalyse} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
