/* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';
import Interweave from 'interweave';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import { IconPlus } from '../Icons';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './Jumbotron.module.scss';

function Jumbotron({ data }) {
  const buttons = typeof data.buttons === 'undefined' ? [] : data.buttons;

  return (
    <div
      className={`${style['jumbotron']}`}
      style={{
        background: `url(${data.image}) no-repeat center top`,
        backgroundSize: 'cover',
      }}
    >
      <div className={`${style['jumbotron__content__overlay']}`} />
      <div className={`container ${style['jumbotron__wrapper']}`}>
        <div className={`${style['jumbotron__content']}`}>
          <div className={`${style['jumbotron__content__heading']}`}>
            <Interweave content={data.title} />
          </div>
          {/* jumbotron__content__heading */}
          <div className={`${style['jumbotron__content__buttons']}`}>
            {buttons.map(oneButton => (
              <a href={oneButton.url} key={oneButton.url}>
                <span className={`${style['content__buttons__icons']}`}>
                  <IconPlus title={oneButton.name} />
                </span>
                <span className={`${style['content__buttons__text']}`}>
                  {oneButton.name}
                </span>
              </a>
            ))}
            {/* <button type="button">1</button>
            <button type="button">2</button>
            <button type="button">3</button>
            <button type="button">4</button>
            <button type="button">5</button>
            <button type="button">6</button>

            <a href="#/sdfsd">2</a>
            <a href="#/sdfsd">3</a>
            <a href="#/sdfsd">4</a>
            <a href="#/sdfsd">5</a>
            <a href="#/sdfsd">6</a> */}
          </div>
          {/* jumbotron__content__buttons */}
          {/* <div className={`${style['jumbotron__content__slider-buttons']}`}>
            <button type="button">1</button>
            <button type="button">2</button>
            <button type="button">3</button>
            <button type="button">4</button>
          </div> */}
        </div>
        {/* jumbotron__content */}
      </div>
      {/* jumbotron__wrapper */}
    </div>
  );
}

// Jumbotron.propTypes = {

// };

// Jumbotron.defaultProps = {

// };

export default Jumbotron;
