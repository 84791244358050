/* eslint-disable */
const infoBoxThreeColumnMockData = [
  {
    mainTitle: 'This is the main title',
    title: 'IT Strategieberatung',
    content: 'Wir senken nicht nur Ihre Kosten und verbessern Ihre Prozesse, sondern machen Ihr Unternehmen auch fit  für die Digitalisierung und den digitalen Wandel. Neben der digitalen Transformation setzen wir uns mit unserer IT-Beratung für Ihre IT-Sicherheit und Compliance ein. Wir schaffen für Sie eine zukunftsorientierte IT-Architektur in Kombination mit passenden IT-Leistungen.',
    button: {
      name: 'Mehr Informationen',
      url: '/leistungen/it-strategieberatung',
    },
  },
  {
    mainTitle: 'Unsere Leistungen',
    title: 'Individuelle Digitalprojekte',
    content: 'Aufgrund unserer Expertise, durch Softwareentwickler und B2B Experten, bieten wir Ihnen ein breitgefächertes Leistungspaket. Dieses reicht vom Internet of Things (IoT) über nutzerorientiertes Software Design, bis hin zu der Optimierung Ihrer Unternehmensprozesse.',
    button: {
      name: 'Mehr Informationen',
      url: '/leistungen/individuelle-digitalprojekte',
    },
  },
  {
    mainTitle: 'ANither Tilte',
    title: 'Systemintegration',
    content: 'Als erfahrene CRM Agentur sind wir Ihr Ansprechpartner für die digitale Transformation. Wir bieten jegliche Leistungen von der Beratung und der Analyse, über die Optimierung und die Automatisierung, bis hin zur kompletten Integration von CRM Systemen und deren Anbindung an vorhandene Schnittstellen.',
    button: {
      name: 'Mehr Informationen',
      url: '/leistungen/systemintegration',
    },
  },
];

export default infoBoxThreeColumnMockData;
