/* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
// SEE: https://reacttraining.com/react-router/web/guides/scroll-restoration
// !!! TO-DO: Clean this up !!!
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
