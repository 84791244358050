// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
// import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_references_optimized.jpg';

// //////////////////////////////
// IMPORT STYLES
import style from './Referenzen.module.scss';

function Referenzen({ image, reverse }) {
  return (
    <Fragment>
      <Header />

      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                Case Studies &ndash;
              </span>{' '}
              <span className="highlight">erleben Sie den Impact</span>
            </h1>
            <div className="two-columns">
              <h2>Systemintegration für Problemlösungen in Echtzeit</h2>
              <p>
                <strong>
                  Auf Wartungsanfragen schnell reagieren und technische Probleme
                  in Windeseile lösen? Und das für anspruchsvolle Kunden in zehn
                  Ländern? Ein Maschinenhersteller aus Baden-Württemberg
                  brauchte hierfür nicht nur eine flexible Lösungsstruktur mit
                  einem automatischen Ticketsystem, sondern auch eine neue
                  Technologie für die prompte Ferndiagnose.
                </strong>
              </p>
              <p>
                Impact Horizon sollte eine Komplettlösung für die Ferndiagnose
                und Wartung der eigenen Maschinen konzipieren und
                implementieren. Der Hersteller vertreibt seine Maschinen in 10
                internationalen Ländern. Die schnelle Reaktion auf
                Wartungsanfragen oder ad-hoc Problemlösungsfällen sind daher
                limitiert. Mithilfe der Einführung der Salesforce™ IoT Cloud
                gelang es Impact Horizon eine flexible Lösungsstruktur zu
                entwickeln: mit dem stabilen und zukunftssicheren System können
                präzise Anwendungen entwickelt werden. Über eine progressive
                Weboberfläche, die mobil und über Desktopgeräte aufgerufen
                werden kann, werden Informationen zur Maschine in Echtzeit
                erfasst, analysiert und darin ausgegeben. Wenn die Sensoren
                Fehler an die IoT Cloud weitergeben, werden diese ausgewertet in
                der Weboberfläche ausgegeben. Ein automatisches Ticket wird
                erstellt und der Support des Kunden bekommt automatisch eine
                Notifikation. Der Support kann in Windeseile reagieren und dem
                Kunden sofort Hilfe zukommen lassen.
              </p>
            </div>

            <div className="two-columns">
              <h2>Neue Digitalstruktur für einen Saatgutbetrieb</h2>
              <p>
                <strong>
                  Mühlelose Verwaltung von über 500 Sorteninformationen mit
                  verschiedenen Ausgabemöglichkeiten? Dem Marketing-Team ein
                  Upgrade mittels progressiver Web-App verpassen? Ein
                  Saatgutzüchter aus Bayern mit einem Jahresumsatz von 20 Mio.
                  Euro wollte seine Pflanzensorten effektiver vermarkten.
                </strong>
              </p>
              <p>
                Bislang verfügte das Unternehmen kaum über digitale Strukturen
                und die Corporate Website bot Interessenten keinen Mehrwert.
                Impact Horizon führte intensive Interviews mit den Mitarbeitern
                im Außendienst und Vertrieb. Es zeigte sich, dass effektive
                Marketing Tools benötigt wurden. Für die Mitarbeiter kreierten
                die Experten von Impact Horizon daher eine Web-App, die
                Außendienst und Vertrieb einen einfach zu bedienenden und
                zentralen Hub für Kundentermine bieten sollte. Sie kann sowohl
                auf einem Desktopgerät als auch auf einem Smartphone benutzt
                werden. Die App ermöglicht Zugriff auf die eigenen Saatsorten,
                Produktinformationen, Organizerfunktionen und schnelles
                Versenden von Produktinformationen. Eine komplett neue, digitale
                Struktur wurde geschaffen: ein „Product Information Management“
                System, das zentral an alle anderen Datenquellen angeschlossen
                ist. Der Kunde kann nun seine Saatsorten darin pflegen und an
                anderen Schnittstellen ausgeben: auf der neu erstellten
                Corporate Website, der Vertriebs-Web-App und im
                E-Mail-Marketing-System, welches automatisierte Newsletter
                versendet. Der Return on Invest des Kunden wurde maßgeblich
                erhöht.
              </p>
            </div>
            <div />
            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Case Studies &ndash;
              <span className="highlight">erleben Sie den Impact</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

Referenzen.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

Referenzen.defaultProps = {
  reverse: false,
  image: IMAGE,
};

export default Referenzen;
