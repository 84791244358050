// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import InfoBox from './InfoBox';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

class InfoBoxComponent extends Component {
  constructor(props) {
    super(props);
    const {
      data: { buttons },
    } = this.props;

    this.state = {
      buttons: typeof buttons === 'undefined' ? [] : buttons,
      toggleOverlay: false,
      displayOverlayContent: '',
    };
  }

  funcIhaveBeenClicked = (event, name) => {
    event.preventDefault();
    const { buttons } = this.state;
    const matchingButton = buttons.filter(button => button.name === name);
    const showThisContent = matchingButton[0].content;

    this.setState({
      toggleOverlay: true,
      displayOverlayContent: showThisContent,
    });
  };

  funcCloseOverlay = () => {
    this.setState({ toggleOverlay: false });
  };

  render() {
    const { data } = this.props;
    const { buttons, displayOverlayContent, toggleOverlay } = this.state;
    return (
      <InfoBox
        data={data}
        stateButtons={buttons}
        stateDisplayOverlayContent={displayOverlayContent}
        stateToggleOverlay={toggleOverlay}
        funcIhaveBeenClicked={this.funcIhaveBeenClicked}
        funcCloseOverlay={this.funcCloseOverlay}
      />
    );
  }
}

InfoBoxComponent.propTypes = {
  data: PropTypes.node.isRequired,
};

// InfoBoxComponent.defaultProps = {

// };

export default InfoBoxComponent;
