// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HOC's or other ABSTRACTS (e.g. Higher Order Functions (HOF), Utils, constants, etc.)
import jumbotronMockData from '../components/Jumbotron/__mockData__jumbotron';
import ThreeColumnMockData from '../components/InfoBoxThreeColumn';
import infoBoxThreeColumnMockData from '../components/InfoBoxThreeColumn/__mockData__infoBoxThreeColumn';

// //////////////////////////////
// IMPORT COMPONENTS
import LayoutOne from '../components/LayoutOne';
import Jumbotron from '../components/Jumbotron';
import GridIcons from '../components/GridIcons';
import GridRender from '../components/GridRender';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES

function PageHome() {
  return (
    <LayoutOne>
      <Jumbotron data={jumbotronMockData} />
      <GridRender
        leftContent="<h4>Wir sind digitale Experten. Seit 2013 entwickeln wir für mittelständische Unternehmen konzeptionell und technisch innovative Digitalstrukturen. Diese sorgen für produktives, präzises und wettbewerbsfähiges Arbeiten Ihres Unternehmens in der Zukunft.</h4>"
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent="<p>Wir beraten Sie ganzheitlich in Ihrer IT-Strategie und kreieren individuelle Software und Webplattformen mit dem Nutzer im Fokus (UX). Zudem integrieren und erweitern wir CRM Systeme, welche von unserer exzellenten hauseigenen IT-Taskforce umgesetzt werden. Mit der vorhandenen Erfahrung analysieren wir Ihre Herausforderung und kümmern uns anschließend um alle Grundlagen sowie die Umsetzung, welche Sie für eine erfolgreiche, digitale Transformation brauchen.</p>"
        rightCss="grid__column--48 grid__left-margin--2"
      />
      <ThreeColumnMockData data={infoBoxThreeColumnMockData} />
      <GridRender
        leftContent={<GridIcons />}
        leftCss="grid__column--48 grid__right-margin--2"
        rightContent='<h4>Über uns</h4> <p>Von der Analyse Ihrer digitalen Prozesse und Marktherausforderungen über die Strategieentwicklung bis zur Umsetzung in exzellentem Code – wir steigen da ein, wo Sie es wünschen. Unsere Produkte und Services werden speziell für Ihre Anforderungen konzipiert und präzise umgesetzt ... </p> <a href="/agentur">Weiterlesen</a> <h4>Wie wir arbeiten</h4> <p>Ideenreiche Konzepter, unabhängige Sparringpartner, präzise Umsetzer: Für Sie stellen wir das Team zusammen, das Sie brauchen. Wir analysieren Ihre Herausforderung und ... </p> <a href="/agentur-herangehensweise">Weiterlesen</a> <h4>Hauseigene IT-Taskforce</h4> <p>Unsere IT-Taskforce besteht aus Spezialisten für ERP und CRM Systemintegrationen, individuelle Softwareentwicklungen, Web- und Desktop-Anwendungen und Product Information Management-Systeme ...</p> <a href="/agentur-taskforce">Weiterlesen</a>'
        rightCss="grid__column--48 grid__left-margin--2"
      />
    </LayoutOne>
  );
}

// PageHome.propTypes = {

// };

// PageHome.defaultProps = {

// };

export default PageHome;
