/* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';
// https://github.com/airbnb/browser-shims
import 'airbnb-browser-shims';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS
// import * as serviceWorker from './services/serviceWorker';

// //////////////////////////////
// IMPORT COMPONENTS



// //////////////////////////////
// IMPORT PAGES
import App from './pages/App';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import './index.module.scss';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
