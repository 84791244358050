// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './Buttons.module.scss'; // eslint-disable-line

const LoadingIcon = () => <div>loading...</div>;

const renderContent = (loading, children) => (
  <span className={`${style['button-wrapper']}`}>
    {loading ? <LoadingIcon /> : children}
  </span>
);

const Buttons = ({
  className,
  children,
  href,
  onClick,
  target,
  rel,
  name,
  loading,
  type,
  role,
  ...attributes
}) =>
  href ? (
    <Link
      to={href}
      className={`${style[className]} ${loading &&
        style['button__button-loading--disabled']}`}
      target={target}
      rel={rel}
      name={name}
      {...attributes}
      disabled={loading}
    >
      {renderContent(loading, children)}
    </Link>
  ) : (
    /* eslint-disable react/button-has-type */
    <button
      role={role}
      type={type}
      onClick={onClick}
      className={`${style[className]} ${loading &&
        style['button__button-loading--disabled']}`}
      target={target}
      rel={rel}
      name={name}
      disabled={loading}
    >
      {renderContent(loading, children)}
    </button>
    /* eslint-enable react/button-has-type */
  );

Buttons.propTypes = {
  // Always remember to add type="button" to non-submit buttons and only
  // have one type="submit" button per form. That helps avoid ambiguity and
  // makes following execution easy.
  // READ: https://dzello.com/blog/2017/02/19/demystifying-enter-key-submission-react-forms-multiple-buttons
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  className: PropTypes.oneOf([
    'button__one',
    'button__one button--full-width',
    'button__one--dark',
    'button__one--dark button--full-width',
    'button__two',
    'button__two button--full-width',
    'button__two--dark',
    'button__two--dark button--full-width',
    'button__three',
    'button__three button--full-width',
    'button__four',
    'button__four button--full-width',
    'button__four--dark',
    'button__four--dark button--full-width',
  ]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  target: PropTypes.string,
  rel: PropTypes.string,
  name: PropTypes.string,
  attributes: PropTypes.node,
  loading: PropTypes.bool,
  // Pass a link or href prop to make the button an `a` element instead of a `button`
  href: PropTypes.string,
  role: PropTypes.string,
};

Buttons.defaultProps = {
  type: 'button',
  children: 'Enter Button Label',
  href: null,
  onClick: null,
  target: null,
  rel: null,
  name: null,
  attributes: null,
  loading: false,
  role: 'button',
};

export default Buttons;
