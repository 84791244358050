// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
import PropTypes from 'prop-types';
import Interweave from 'interweave';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import { IconPlus, IconX } from '../Icons';

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './InfoBox.module.scss';

function InfoBox({
  data,
  stateButtons,
  stateToggleOverlay,
  stateDisplayOverlayContent,
  funcIhaveBeenClicked,
  funcCloseOverlay,
}) {
  return (
    <section className={`${style['info-box']}`}>
      {stateToggleOverlay && (
        <div className={`${style['info-box__overlay']}`}>
          <button
            type="button"
            className={`${style['info-box__overlay__close']}`}
            onClick={() => funcCloseOverlay()}
          >
            <IconX title="Close" />
          </button>
          <Interweave content={stateDisplayOverlayContent} />
        </div>
      )}
      <div className={`container ${style['info-box__wrapper']}`}>
        <h2>{data.mainTitle}</h2>
        <Interweave content={data.content} />

        <div className={`${style['info-box__button-list']}`}>
          {stateButtons.map(button => (
            <button
              key={button.name}
              type="button"
              // href={`${button.url}`}
              onClick={() => funcIhaveBeenClicked(event, button.name)} // eslint-disable-line
              className={`${style['info-box__button-list__button']}`}
            >
              <span className={`${style['info-box__button__icon']}`}>
                <IconPlus title={button.name} />
              </span>
              <span className={`${style['info-box__button__text']}`}>
                {button.name}
              </span>
            </button>
          ))}
        </div>
        {/* info-box__wrapper */}
      </div>
    </section>
  );
}

InfoBox.propTypes = {
  data: PropTypes.node.isRequired,
  stateButtons: PropTypes.node.isRequired,
  stateDisplayOverlayContent: PropTypes.string.isRequired,
  stateToggleOverlay: PropTypes.bool.isRequired,
  funcIhaveBeenClicked: PropTypes.func.isRequired,
  funcCloseOverlay: PropTypes.func.isRequired,
};

// InfoBox.defaultProps = {

// };

export default InfoBox;
