// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
// import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_career_optimized.jpg';

// //////////////////////////////
// IMPORT STYLES
import style from './Karriere.module.scss';

function Karriere({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                Hiring Excellence &ndash;
              </span>{' '}
              <span className="highlight">werde Teil des Teams</span>
            </h1>
            <div className="two-columns">
              <h2>
                Du suchst neue Herausforderungen, möchtest Dich weiterentwickeln
                oder brauchst einfach nur einen Tapetenwechsel?
              </h2>
              <p>
                Wir sind ein Team aus kreativen Köpfen und talentierten
                Softwareingeneuren mit flachen Hierarchien. Wir suchen ständig
                neue Talente um unsere Reihen zu verstärken.
              </p>
              {/* <div className="special-p">
                <a
                  href="mailto:hiring@impacthorizon.de"
                  className="button__one--dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Online Marketing Specialist (m/w/d) [München]
                </a>
              </div>
              <div className="special-p">
                <a
                  href="mailto:hiring@impacthorizon.de"
                  className="button__one--dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  UX Designer (m/w/d) [München]
                </a>
              </div>
              <div className="special-p">
                <a
                  href="mailto:hiring@impacthorizon.de"
                  className="button__one--dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Cloud Engineer [AWS Cloud] (m/w/d) [München]
                </a>
              </div> */}
              <div className="special-p">
                <a
                  href="mailto:hiring@impacthorizon.de"
                  className="button__one--dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Software Engineer (Backend) (m/w/d) [München]
                </a>
              </div>
              <div className="special-p">
                <a
                  href="mailto:hiring@impacthorizon.de"
                  className="button__one--dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  x2 Software Engineer (IoT) (m/w/d) [München]
                </a>
              </div>
              <div className="special-p">
                <a
                  href="mailto:hiring@impacthorizon.de"
                  className="button__one--dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Software Engineer (DevOps) (m/w/d) [München]
                </a>
              </div>
              <br />
              <br />
            </div>

            <div className="two-columns">
              <h2>
                Du bist Freelancer? Sehr gut, tatkräftige Unterstützung auf
                Projektbasis wird immer gebraucht
              </h2>
              <p>
                Schaue in unserem Office vorbei und stell dich uns vor. Wir sind
                sehr gespannt Dich kennenzulernen!
              </p>
            </div>

            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Hiring Excellence &ndash;
              <span className="highlight">werde Teil des Teams</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

Karriere.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

Karriere.defaultProps = {
  reverse: true,
  image: IMAGE,
};

export default Karriere;
