// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
// import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_impressum_optimized.jpg';

// //////////////////////////////
// IMPORT STYLES
import style from './ImpressumDatenschutz.module.scss';

function ImpressumDatenschutz({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                <span className="highlight">Impressum</span> &amp;
              </span>{' '}
              <span className="highlight">Datenschutzerklärung</span>
            </h1>
            <div>
              <h2>Impressum</h2>
              <p>
                <strong>Angaben gemäß § 5 TMG:</strong>
                <br />
                Impact Horizon Ltd Zweigniederlassung München
                <br />
                Hopfenstraße 8<br />
                80335 München
              </p>
              <p>
                Impact Horizon Ltd Zweigniederlassung München wird vertreten
                durch:
                <br />
                Arman Nisch (Geschäftsführer)
              </p>
              <p>
                <strong>Kontakt:</strong>
                <br />
                Telefon: +49 89 235 13504-0
                <br />
                Telefax: +49 89 235 13504-8
                <br />
                E-Mail: info@impacthorizon.de
              </p>
              <p>
                <strong>Registereintrag:</strong>
                <br />
                Eintragung im Handelsregister.
                <br />
                Registergericht: Amtsgericht München
                <br />
                Registernummer: HRB-208628
              </p>
              <p>
                <strong>Umsatzsteuer-ID:</strong>
                <br />
                Umsatzsteuer-Identifikationsnummer gemäß §27 a
                Umsatzsteuergesetz:
                <br />
                DE 293 999 297
              </p>
              <p>
                <strong>Hinweis auf EU-Streitschlichtung</strong>
                <br />
                Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit: <br />
                http://ec.europa.eu/consumers/odr
                <br />
                <br />
                Unsere E-Mail-Adresse finden sie oben im Impressum.
              </p>
              <p>Quelle: https://www.e-recht24.de</p>

              <h3>Haftungsausschluss (Disclaimer)</h3>
              <p>
                <strong>Haftung für Inhalte</strong>
                <br />
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                werden wir diese Inhalte umgehend entfernen.
              </p>
              <p>
                <strong>Haftung für Links</strong>
                <br />
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
              </p>
              <p>
                <strong>Urheberrecht</strong>
                <br />
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
              </p>
            </div>
            <div>
              <h2>Datenschutzerklärung</h2>
              <p>
                {' '}
                Die nachfolgende Datenschutzerkl&auml;rung gilt für die Nutzung
                der Webseite https://www.impacthorizon.de/ (nachfolgend
                „Website“).{' '}
              </p>
              <p>
                {' '}
                Wir messen dem Datenschutz gro&szlig;e Bedeutung bei. Die
                Erhebung und Verarbeitung Ihrer personenbezogenen Daten
                geschieht unter Beachtung der geltenden datenschutzrechtlichen
                Vorschriften, insbesondere der EU-Datenschutzgrundverordnung
                (DSGVO). Wir erheben und verarbeiten Ihre personenbezogenen
                Daten, um Ihnen das oben genannten Portal anbieten zu
                k&ouml;nnen. Diese Erkl&auml;rung beschreibt, wie und zu welchem
                Zweck Ihre Daten erfasst und genutzt werden und welche
                Wahlm&ouml;glichkeiten Sie im Zusammenhang mit pers&ouml;nlichen
                Daten haben.{' '}
              </p>
              <p>
                Durch Ihre Verwendung dieser Website stimmen Sie der Erfassung,
                Nutzung und &Uuml;bertragung Ihrer Daten gem&auml;&szlig; dieser
                Datenschutzerkl&auml;rung zu.{' '}
              </p>
              <p>
                <strong>1 Verantwortliche Stelle</strong>
                <br /> <br /> Verantwortliche Stelle für die Erhebung,
                Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne
                der DSGVO ist: <br /> <br /> Impact Horizon Ltd
                Zweigniederlassung M&uuml;nchen
                <br /> Hopfenstra&szlig;e 8<br /> 80335 M&uuml;nchen
                <br /> <br /> <br /> Telefon: +49 89 235 13504-0
                <br /> Telefax: +49 89 235 13504-8
                <br /> E-Mail: info@impacthorizon.de
                <br /> <br /> <br /> Sofern Sie der Erhebung, Verarbeitung oder
                Nutzung Ihrer Daten durch uns nach Ma&szlig;gabe dieser
                Datenschutzbestimmungen insgesamt oder für einzelne
                Ma&szlig;nahmen widersprechen wollen, k&ouml;nnen Sie Ihren
                Widerspruch an oben genannte verantwortliche Stelle richten. Sie
                k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit speichern
                und ausdrucken.{' '}
              </p>
              <p>
                <strong>2 Allgemeine Nutzung der Webseite</strong>
              </p>
              <p>
                <strong>2.1 Zugriffsdaten</strong> <br /> <br /> Wir sammeln
                Informationen über Sie, wenn Sie diese Webseite nutzen. Wir
                erfassen automatisch Informationen über Ihr Nutzungsverhalten
                und Ihre Interaktion mit uns und registrieren Daten zu Ihrem
                Computer oder Mobilgerät. Wir erheben, speichern und nutzen
                Daten über jeden Zugriff auf unser Onlineangebot (sogenannte
                Serverlogfiles). Zu den Zugriffsdaten gehören Name und URL der
                abgerufenen Datei, Datum und Uhrzeit des Abrufs, übertragene
                Datenmenge, Meldung über erfolgreichen Abruf (HTTP response
                code), Browsertyp und Browserversion, Betriebssystem, Referrer
                URL (d.h. die zuvor besuchte Seite), IP-Adresse und der
                anfragende Provider. Wir nutzen diese Protokolldaten ohne
                Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für
                statistische Auswertungen zum Zweck des Betriebs, der Sicherheit
                und der Optimierung unseres Onlineangebotes, aber auch zur
                anonymen Erfassung der Anzahl der Besucher auf unserer Webseite
                (traffic) sowie zu Umfang und zur Art der Nutzung unserer
                Webseite und Dienste, ebenso zu Abrechnungszwecken, um die
                Anzahl der von Kooperationspartnern erhaltenen Clicks zu messen.
                Aufgrund dieser Informationen können wir personalisierte und
                standortbezogene Inhalte zur Verfügung stellen und den
                Datenverkehr analysieren, Fehler suchen und beheben und unsere
                Dienste verbessern. Wir behalten uns vor, die Protokolldaten
                nachträglich zu überprüfen, wenn aufgrund konkreter
                Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen
                Nutzung besteht. IP-Adressen speichern wir für einen begrenzten
                Zeitraum in den Logfiles, wenn dies für Sicherheitszwecke
                erforderlich oder für die Leistungserbringung oder die
                Abrechnung einer Leistung nötig ist, z. B. wenn Sie eines
                unserer Angebote nutzen. Nach Abbruch des Vorgangs der
                Bestellung oder nach Zahlungseingang löschen wir die IP-Adresse,
                wenn diese für Sicherheitszwecke nicht mehr erforderlich sind.
                IP-Adressen speichern wir auch dann, wenn wir den konkreten
                Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer
                Website haben. Au&szlig;erdem speichern wir als Teil Ihres
                Accounts das Datum Ihres letzten Besuchs (z.B. bei
                Registrierung, Login, Klicken von Links etc.).{' '}
              </p>
              <p>
                <strong>2.2 E-Mail Kontakt</strong>
                <br /> <br /> Wenn Sie mit uns in Kontakt treten (z. B. per
                Kontaktformular oder E-Mail), speichern wir Ihre Angaben zur
                Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen
                entstehen. Weitere personenbezogene Daten speichern und nutzen
                wir nur, wenn Sie dazu einwilligen oder dies ohne besondere
                Einwilligung gesetzlich zulässig ist.{' '}
              </p>
              <p>
                <strong>2.3.1 Google Analytics</strong>
                <br /> <br /> Wir benutzen Google Analytics, einen
                Webanalysedienst der Google Inc. („Google“). Google Analytics
                verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer
                gespeichert werden und die eine Analyse der Benutzung der
                Website durch Sie ermöglichen. Die durch den Cookie erzeugten
                Informationen über Benutzung dieser Website durch die
                Seitenbesucher werden in der Regel an einen Server von Google in
                den USA übertragen und dort gespeichert. Im Falle der
                Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre
                IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
                Europäischen Union oder in anderen Vertragsstaaten des Abkommens
                über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
                Ausnahmefällen wird die volle IP-Adresse an einen Server von
                Google in den USA übertragen und dort gekürzt. Die
                IP-Anonymisierung ist auf dieser Website aktiv. In unserem
                Auftrag wird Google diese Informationen benutzen, um die Nutzung
                der Website durch Sie auszuwerten, um Reports über die
                Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen uns gegenüber zu erbringen. <br /> <br /> Die
                im Rahmen von Google Analytics von Ihrem Browser übermittelte
                IP-Adresse wird nicht mit anderen Daten von Google
                zusammengeführt. Sie können die Speicherung der Cookies durch
                eine entsprechende Einstellung Ihrer Browser-Software
                verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
                Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                vollumfänglich werden nutzen können. <br /> <br /> Sie können
                darüber hinaus die Erfassung der durch das Cookie erzeugten und
                auf ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                Google verhindern, indem Sie das unter dem folgenden Link
                verfügbare Browser-Plugin herunterladen und installieren:
                http://tools.google.com/dlpage/gaoptout?hl=de. <br /> <br />{' '}
                Alternativ zum Browser-Plugin oder innerhalb von Browsern auf
                mobilen Geräten können Sie auf den folgenden Link klicken, um
                ein Opt-Out-Cookie zu setzen, der die Erfassung durch Google
                Analytics innerhalb dieser Website zukünftig verhindert (dieses
                Opt-Out-Cookie funktioniert nur in diesem Browser und nur für
                diese Domain. Löschen Sie die Cookies in Ihrem Browser, müssen
                Sie diesen Link erneut klicken): Google Analytics deaktivieren{' '}
              </p>
              <p>
                <strong>2.3.2 Google Tag Manager</strong>
                <br /> <br /> Diese Webseite verwendet den Google Tag Manager.
                Durch diesen Dienst können Website-Tags über eine Oberfläche
                verwaltet werden. Der Google Tool Manager implementiert
                lediglich Tags. Das bedeutet: Es werden keine Cookies eingesetzt
                und es werden keine personenbezogenen Daten erfasst. Der Google
                Tool Manager löst andere Tags aus, die wiederum ggf. Daten
                erfassen. Jedoch greift der Google Tag Manager nicht auf diese
                Daten zu. Wurde auf Domain- oder Cookie-Ebene eine Deaktivierung
                vorgenommen, so bleibt sie für alle Tracking-Tags bestehen,
                insofern diese mit dem Google Tag Manager implementiert werden.
                verwaltet werden. Der Google Tool Manager implementiert
                lediglich Tags. Das bedeutet: Es werden keine Cookies eingesetzt
                und es werden keine personenbezogenen Daten erfasst. Der Google
                Tool Manager löst andere Tags aus, die wiederum ggf. Daten
                erfassen. Jedoch greift der Google Tag Manager nicht auf diese
                Daten zu. Wurde auf Domain- oder Cookie-Ebene eine Deaktivierung
                vorgenommen, so bleibt sie für alle Tracking-Tags bestehen,
                insofern diese mit dem Google Tag Manager implementiert werden.
              </p>
              <p>
                <strong>2.3.3 Google Re-Targeting</strong>
                <br /> <br /> Diese Website verwendet Retargeting-Technologie
                der Google Inc. („Google”). Diese ermöglicht es, Besucher
                unserer Internetseiten gezielt mit personalisierter,
                interessenbezogener Werbung anzusprechen, die sich bereits für
                unsere Produkte interessiert haben. Die Einblendung der
                Werbemittel erfolgt dabei auf Basis einer Cookie-basierten
                Analyse des früheren Nutzungsverhaltens, wobei aber keine
                personenbezogenen Daten gespeichert werden. In den Fällen der
                Retargeting-Technologie wird ein Cookie auf Ihrem Computer oder
                mobilen Endgerät gespeichert, um anonymisierte Daten über Ihre
                Interessen zu erfassen und so die Werbung individuell auf die
                gespeicherten Informationen anzupassen. Sie bekommen dadurch
                Werbung angezeigt, die mit hoher Wahrscheinlichkeit Ihren
                Produkt- und Informationsinteressen entspricht. Sie können der
                Setzung von Cookies für Anzeigenvorgaben dauerhaft
                widersprechen, indem Sie das unter folgendem Link verfügbare
                Browser-Plug-in herunterladen und installieren:
                http://www.google.com/ads/preferences/html/intl/de/plugin/
                <br />
                Weitergehende Informationen und die Datenschutzbestimmungen
                bezüglich Werbung und Google können Sie hier einsehen:
                http://www.google.com/privacy/ads/
              </p>
              <p>
                <strong>2.3.4 Einbindung der Dienste Dritter</strong>
                <br /> <br /> Wir nutzen auf unseren Webseiten auf Grundlage
                unserer berechtigten Interessen (Interesse an der Analyse,
                Optimierung und Betrieb unseres Onlineangebotes im Sinne des
                Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
                Drittanbietern.
                <br />
                <br /> Für die Funktion dieser Inhalte ist Voraussetzung, dass
                die Anbieter dieser Angebote die IP-Adresse der Nutzer
                wahrnehmen. Die IP-Adresse ist für die Darstellung und Nutzung
                dieser Inhalte erforderlich.
                <br />
                <br /> Die Drittanbieter können jedoch auch Pixel-Tags oder
                Cookies für statistische Zwecke oder Marketingzwecke einsetzen.
                Pixel-Tags sind unsichtbare Grafiken und werden auch als `Web
                Beacons` bezeichnet. Den Anbietern ist damit möglich weitere
                Informationen über die Nutzung von Webseiten zu ermitteln und
                auszuwerten. Wir informieren Sie in dieser Datenschutzerklärung
                wie Sie die Nutzung der im Regelfall anonymen Daten unterbinden
                und einschränken können.
              </p>
              <p>
                <strong>2.3.5 Facebook</strong>
                <br /> <br /> Auf unseren Seiten sind Plugins des sozialen
                Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo
                Park, California 94025, USA, integriert. Die Facebook-Plugins
                erkennen Sie an dem Facebook-Logo oder dem „Like-Button“
                („Gefällt mir“) auf unserer Seite. Eine Übersicht über die
                Facebook-Plugins finden Sie hier:
                http://developers.facebook.com/docs/plugins/.
                <br /> Wenn Sie unsere Seiten besuchen, wird über das Plugin
                eine direkte Verbindung zwischen Ihrem Browser und dem
                Facebook-Server hergestellt. Facebook erhält dadurch die
                Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht
                haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie
                in Ihrem Facebook-Account eingeloggt sind, können Sie die
                Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken.
                Dadurch kann Facebook den Besuch unserer Seiten Ihrem
                Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als
                Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
                Daten sowie deren Nutzung durch Facebook erhalten. Weitere
                Informationen hierzu finden Sie in der Datenschutzerklärung von
                Facebook unter http://de-de.facebook.com/policy.php.
                <br /> Wenn Sie nicht wünschen, dass Facebook den Besuch unserer
                Seiten Ihrem Facebook- Nutzerkonto zuordnen kann, loggen Sie
                sich bitte aus Ihrem Facebook-Benutzerkonto aus.
              </p>
              <p>
                <strong>2.3.6 Facebook Tracking Pixel</strong>
                <br /> <br /> Innerhalb unseres Internetauftritts setzen wir mit
                Ihrer Einwilligung das „Tracking-Pixel” der Facebook Inc., 1601
                S. California Ave, Palo Alto, CA 94304, USA (“Facebook”) ein.
                Mit Hilfe dieses Pixels kann das Verhalten von Nutzern
                nachverfolgt werden, nachdem diese durch Klick auf eine
                Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden.
                So können wir die Wirksamkeit der Facebook-Werbeanzeigen für
                statistische und Marktforschungszwecke erfassen. Die so
                erfassten Daten sind für uns anonym, das heißt wir sehen nicht
                die personenbezogenen Daten einzelner Nutzer. Diese Daten werden
                jedoch von Facebook gespeichert und verarbeitet, worüber wir Sie
                entsprechend unserem Kenntnisstand unterrichten. Facebook kann
                diese Daten mit ihrem Facebook-Konto verbinden und auch für
                eigene Werbezwecke, entsprechend Facebooks
                Datenverwendungsrichtlinie verwenden. Sie haben die Möglichkeit,
                Facebook sowie dessen Partnern das Schalten von Werbeanzeigen zu
                untersagen. Sie können die Einstellungen für Facebooks
                Werbeanzeigen unter folgendem Link bearbeiten:
                https://www.facebook.com/ads/website_custom_audiences/.
              </p>
              <p>
                <strong>2.3.7 Facebook Custom Audiences</strong>
                <br /> <br /> Wir setzen Kommunikationstools des sozialen
                Netzwerkes Facebook ein, insbesondere das Produkt Custom
                Audiences und Website Custom Audiences. Grundsätzlich wird dabei
                eine nicht-reversible und nicht-personenbezogene Prüfsumme
                (Hash-Wert) aus Ihren Nutzungsdaten generiert, die an Facebook
                zu Analyse- und Marketingzwecken übermittelt werden kann. Für
                das Produkt Website Custom Audiences wird der Facebook Cookie
                angesprochen.
                <br /> Weitere Informationen über Zweck und Umfang der
                Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
                durch Facebook sowie Ihre Einstellungsmöglichkeiten zum Schutz
                Ihrer Privatsphäre entnehmen Sie bitte den
                Datenschutzrichtlinien von Facebook, welche u.a.
                <br /> auf
                https://www.facebook.com/ads/website_custom_audiences/ und
                https://www.facebook.com/privacy/explanation zu finden sind.
                Wenn Sie der Nutzung von Facebook Website Custom Audiences
                widersprechen möchten, können Sie dies unter
                https://www.facebook.com/ads/website_custom_audiences/ tun. Wenn
                Sie der Nutzung von Facebook Custom Audiences widersprechen
                möchten, melden Sie sich bitte von unseren Webservices ab.
              </p>
              <p>
                <strong>2.3.8 Instagram</strong>
                <br /> <br /> Auf unseren Seiten sind Funktionen des Dienstes
                Instagram eingebunden. Diese Funktionen werden angeboten durch
                die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025,
                USA. Wenn Sie in Ihrem Instagram – Account eingeloggt sind,
                können Sie durch Anklicken des Instagram – Buttons die Inhalte
                unserer Seiten mit Ihrem Instagram – Profil verlinken. Dadurch
                kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto
                zuordnen. Wir weisen darauf hin, dass wir als Anbieter der
                Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie
                deren Nutzung durch Instagram erhalten.
                <br /> Weitere Informationen hierzu finden Sie in der
                Datenschutzerklärung von Instagram:
                http://instagram.com/about/legal/privacy/
              </p>
              <p>
                <strong>2.3.9 Pinterest</strong>
                <br /> <br /> Auf unserer Seite verwenden wir Social Plugins des
                sozialen Netzwerkes Pinterest, das von der Pinterest Inc., 635
                High Street, Palo Alto, CA, 94301, USA („Pinterest“) betrieben
                wird. Wenn Sie eine Seite aufrufen die ein solches Plugin
                enthält, stellt Ihr Browser eine direkte Verbindung zu den
                Servern von Pinterest her. Das Plugin übermittelt dabei
                Protokolldaten an den Server von Pinterest in die USA. Diese
                Protokolldaten enthalten möglicherweise Ihre IP-Adresse, die
                Adresse der besuchten Websites, die ebenfalls
                Pinterest-Funktionen enthalten, Art und Einstellungen des
                Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise
                von Pinterest sowie Cookies.
                <br /> Weitere Informationen zu Zweck, Umfang und weiterer
                Verarbeitung und Nutzung der Daten durch Pinterest sowie Ihre
                diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer
                Privatsphäre finden Sie in den den Datenschutzhinweisen von
                Pinterest: https://about.pinterest.com/de/privacy-policy
              </p>
              <p>
                <strong>2.3.10 Twitter</strong>
                <br /> <br /> Auf unseren Seiten sind Funktionen des Dienstes
                Twitter eingebunden. Diese Funktionen werden angeboten durch die
                Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA
                94103, USA. Durch das Benutzen von Twitter und der Funktion
                „Re-Tweet“ werden die von Ihnen besuchten Webseiten mit Ihrem
                Twitter-Account verknüpft und anderen Nutzern bekannt gegeben.
                Dabei werden auch Daten an Twitter übertragen. Wir weisen darauf
                hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                der übermittelten Daten sowie deren Nutzung durch Twitter
                erhalten. Weitere Informationen hierzu finden Sie in der
                Datenschutzerklärung von Twitter unter
                http://twitter.com/privacy.
                <br /> Ihre Datenschutzeinstellungen bei Twitter können Sie in
                den Konto-Einstellungen unter
                http://twitter.com/account/settings ändern.
              </p>
              <p>
                <strong>2.3.11 LinkedIn</strong>
                <br /> <br /> Unsere Website nutzt Funktionen des Netzwerks
                LinkedIn. Anbieter ist die LinkedIn Corporation, 2029 Stierlin
                Court, Mountain View, CA 94043, USA. Bei jedem Abruf einer
                unserer Seiten, die Funktionen von LinkedIn enthält, wird eine
                Verbindung zu Servern von LinkedIn aufbaut. LinkedIn wird
                darüber informiert, dass Sie unsere Internetseiten mit Ihrer
                IP-Adresse besucht haben. Wenn Sie den „Recommend-Button“ von
                LinkedIn anklicken und in Ihrem Account bei LinkedIn eingeloggt
                sind, ist es LinkedIn möglich, Ihren Besuch auf unserer
                Internetseite Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir
                weisen darauf hin, dass wir als Anbieter der Seiten keine
                Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
                durch LinkedIn haben.
                <br /> Weitere Informationen hierzu finden Sie in der
                Datenschutzerklärung von LinkedIn unter:
                https://www.linkedin.com/legal/privacy-policy
              </p>
              <p>
                <strong>2.3.12 Xing</strong>
                <br /> <br /> Unsere Webseite nutzt Funktionen des Netzwerks
                XING. Anbieter ist die XING AG, Dammtorstraße 29-32, 20354
                Hamburg, Deutschland. Bei jedem Abruf einer unserer Seiten, die
                Funktionen von Xing enthält, wird eine Verbindung zu Servern von
                Xing hergestellt. Eine Speicherung von personenbezogenen Daten
                erfolgt dabei nach unserer Kenntnis nicht. Insbesondere werden
                keine IP-Adressen gespeichert oder das Nutzungsverhalten
                ausgewertet.
                <br /> Weitere Information zum Datenschutz und dem Xing
                Share-Button finden Sie in der Datenschutzerklärung von Xing
                unter https://www.xing.com/app/share?op=data_protection
              </p>
              <p>
                <strong>2.3.13 YouTube</strong>
                <br /> <br /> Unsere Webseite nutzt Plugins der von Google
                betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube,
                LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine
                unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen,
                wird eine Verbindung zu den Servern von YouTube hergestellt.
                Dabei wird dem Youtube-Server mitgeteilt, welche unserer Seiten
                Sie besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt
                sind ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
                persönlichen Profil zuzuordnen. Dies können Sie verhindern,
                indem Sie sich aus Ihrem YouTube-Account ausloggen.
                <br /> Weitere Informationen zum Umgang von Nutzerdaten finden
                Sie in der Datenschutzerklärung von YouTube unter
                https://www.google.de/intl/de/policies/privacy
              </p>
              <p>
                <strong>
                  2.3.14 Datenschutz bei Bewerbungen und im Bewerbungsverfahren
                </strong>
                <br /> <br /> Wir erheben und verarbeiten personenbezogenen
                Daten von Bewerbern zum Zwecke der Abwicklung des
                Bewerbungsverfahrens. Die Verarbeitung kann auch auf
                elektronischem Wege erfolgen. Dies ist insbesondere dann der
                Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf
                dem elektronischen Wege, beispielsweise per E-Mail an uns
                übermittelt. Schließen wir einen Anstellungsvertrag mit einem
                Bewerber, werden die übermittelten Daten zum Zwecke der
                Abwicklung des Beschäftigungsverhältnisses unter Beachtung der
                gesetzlichen Vorschriften gespeichert. Wird kein
                Anstellungsvertrag mit dem Bewerber geschlossen, so werden die
                Bewerbungsunterlagen drei Monate nach Bekanntgabe der
                Absageentscheidung von uns gelöscht, sofern einer Löschung keine
                sonstigen berechtigten Interessen entgegenstehen oder der/die
                Betroffene sich damit einverstanden erklärt hat, die Daten für
                eine später in Frage kommende Anstellung speichern zu lassen.
                Sonstiges berechtigtes Interesse in diesem Sinne ist
                beispielsweise eine Beweispflicht in einem Verfahren nach dem
                Allgemeinen Gleichbehandlungsgesetz (AGG).
                <br /> Die Verarbeitung der Daten erfolgt, weil Sie durch die
                Übersendung einwilligen (Art. 6 Abs. 1 S. 1 lit. a DSGVO) und
                wir diese dann im berechtigten Interesse verarbeiten (Art. 6
                Abs. 1 S. lit. f DSGVO).
              </p>
              <p>
                <strong>2.4 Rechtsgrundlagen und Speicherdauer</strong>
                <br /> <br /> Rechtsgrundlage der Datenverarbeitung nach den
                vorstehenden Ziffern ist Art 6 Abs. 1 Buchstabe f) DSGVO. Unsere
                Interessen an der Datenverarbeitung sind insbesondere die
                Sicherstellung des Betriebs und der Sicherheit der Webseite, die
                Untersuchung der Art und Weise der Nutzung der Webseite durch
                Besucher, und die Vereinfachung der Nutzung der Webseite. Sofern
                nicht spezifisch angegeben speichern wir personenbezogene Daten
                nur so lange, wie dies zur Erfüllung der verfolgten Zwecke
                notwendig ist{' '}
              </p>
              <p>
                <strong>2.5 Hotjar</strong>
                <br /> <br />
                Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser zu
                verstehen und das Angebot auf dieser Webseite zu optimieren.
                Mithilfe der Technologie von Hotjar bekommen wir eine besseres
                Verständnis von den Erfahrungen unserer Nutzer (z.B. wieviel
                Zeit Nutzer auf welchen Seiten verbringen, welche Links sie
                anklicken, was sie mögen und was nicht etc.) und das hilft uns,
                unser Angebot am Feedback unserer Nutzer auszurichten. Hotjar
                arbeitet mit Cookies und anderen Technologien, um Informationen
                über das Verhalten unserer Nutzer und über ihre Endgeräte zu
                sammeln (insbesondere IP Adresse des Geräts (wird nur in
                anonymisierter Form erfasst und gespeichert), Bildschirmgröße,
                Gerätetyp (Unique Device Identifiers), Informationen über den
                verwendeten Browser, Standort (nur Land), zum Anzeigen unserer
                Webseite bevorzugte Sprache). Hotjar speichert diese
                Informationen in einem pseudonymisierten Nutzerprofil. Die
                Informationen werden weder von Hotjar noch von uns zur
                Identifizierung einzelner Nutzer verwendet oder mit weiteren
                Daten über einzelne Nutzer zusammengeführt. Weitere
                Informationen finden Sie in Hotjars Datenschutzerklärung
                (https://www.hotjar.com/legal/policies/privacy) hier. Sie können
                der Speicherung eines Nutzerprofils und von Informationen über
                Ihren Besuch auf unserer Webseite durch Hotjar sowie dem Setzen
                von Hotjar Tracking Cookies auf anderen Webseiten widersprechen,
                wenn Sie diesen Opt-Out-Link
                (https://www.hotjar.com/legal/compliance/opt-out) anklicken.
              </p>
              <p>
                <strong>
                  3 Ihre Rechte als von der Datenverarbeitung Betroffener
                </strong>
                <br /> <br /> Nach den anwendbaren Gesetzen haben Sie
                verschiedene Rechte bezüglich ihrer personenbezogenen Daten.
                Möchten Sie diese Rechte geltend machen, so richten Sie Ihre
                Anfrage bitte per E-Mail oder per Post unter eindeutiger
                Identifizierung Ihrer Person an die in Ziffer 1 genannte
                Adresse. Nachfolgend finden Sie eine Übersicht über Ihre Rechte.{' '}
              </p>
              <p>
                <strong>3.1 Recht auf Bestätigung und Auskunft</strong>
                <br /> <br /> Sie haben jederzeit das Recht, von uns eine
                Bestätigung darüber zu erhalten, ob Sie betreffende
                personenbezogene Daten verarbeitet werden. Ist dies der Fall, so
                haben Sie das Recht, von uns eine unentgeltliche Auskunft über
                die zu Ihnen gespeicherten personenbezogenen Daten nebst einer
                Kopie dieser Daten zu erlangen. Desweiteren besteht ein Recht
                auf folgende Informationen: <br /> <br /> die
                Verarbeitungszwecke; die Kategorien personenbezogener Daten, die
                verarbeitet werden; die Empfänger oder Kategorien von
                Empfängern, gegenüber denen die personenbezogenen Daten
                offengelegt worden sind oder noch offengelegt werden,
                insbesondere bei Empfängern in Drittländern oder bei
                internationalen Organisationen; falls möglich die geplante
                Dauer, für die die personenbezogenen Daten gespeichert werden,
                oder, falls dies nicht möglich ist, die Kriterien für die
                Festlegung dieser Dauer; das Bestehen eines Rechts auf
                Berichtigung oder Löschung der Sie betreffenden
                personenbezogenen Daten oder auf Einschränkung der Verarbeitung
                durch den Verantwortlichen oder eines Widerspruchsrechts gegen
                diese Verarbeitung; das Bestehen eines Beschwerderechts bei
                einer Aufsichtsbehörde; wenn die personenbezogenen Daten nicht
                bei Ihnen erhoben werden, alle verfügbaren Informationen über
                die Herkunft der Daten; das Bestehen einer automatisierten
                Entscheidungsfindung einschlie&szlig;lich Profiling gemä&szlig;
                Artikel 22 Absätze 1 und 4 DSGVO und – zumindest in diesen
                Fällen – aussagekräftige Informationen über die involvierte
                Logik sowie die Tragweite und die angestrebten Auswirkungen
                einer derartigen Verarbeitung für Sie. Werden personenbezogene
                Daten an ein Drittland oder an eine internationale Organisation
                übermittelt, so haben Sie das Recht, über die geeigneten
                Garantien gemä&szlig; Artikel 46 DSGVO im Zusammenhang mit der
                Übermittlung unterrichtet zu werden{' '}
              </p>
              <p>
                <strong>3.2 Recht auf Berichtigung</strong>
                <br /> <br /> Sie haben das Recht, von uns unverzüglich die
                Berichtigung Sie betreffender unrichtiger personenbezogener
                Daten zu verlangen. Unter Berücksichtigung der Zwecke der haben
                Sie das Recht, die Vervollständigung unvollständiger
                personenbezogener Daten – auch mittels einer ergänzenden
                Erklärung – zu verlangen.{' '}
              </p>
              <p>
                <strong>
                  3.3 Recht auf Löschung („Recht auf Vergessenwerden“)
                </strong>
                <br /> <br /> Sie haben das Recht, von uns zu verlangen, dass
                Sie betreffende personenbezogene Daten unverzüglich gelöscht
                werden, und wir sind verpflichtet, personenbezogene Daten
                unverzüglich zu löschen, sofern einer der folgenden Gründe
                zutrifft: Die personenbezogenen Daten sind für die Zwecke, für
                die sie erhoben oder auf sonstige Weise verarbeitet wurden,
                nicht mehr notwendig. Sie widerrufen Ihre Einwilligung, auf die
                sich die Verarbeitung gemä&szlig; Artikel 6 Absatz 1 DSGVO
                Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO stützte,
                und es fehlt an einer anderweitigen Rechtsgrundlage für die
                Verarbeitung. Sie legen gemä&szlig; Artikel 21 Absatz 1 DSGVO
                Widerspruch gegen die Verarbeitung ein und es liegen keine
                vorrangigen berechtigten Gründe für die Verarbeitung vor, oder
                Sie legen gemä&szlig; Artikel 21 Absatz 2 DSGVO Widerspruch
                gegen die Verarbeitung ein. Die personenbezogenen Daten wurden
                unrechtmä&szlig;ig verarbeitet. Die Löschung der
                personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                Verpflichtung nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten erforderlich, dem wir unterliegen. Die
                personenbezogenen Daten wurden in Bezug auf angebotene Dienste
                der Informationsgesellschaft gemä&szlig; Artikel 8 Absatz 1
                DSGVO erhoben. Haben wir die personenbezogenen Daten öffentlich
                gemacht und sind wir gemä&szlig; zu deren Löschung verpflichtet,
                so treffen wir unter Berücksichtigung der verfügbaren
                Technologie und der Implementierungskosten angemessene
                Ma&szlig;nahmen, auch technischer Art, um für die
                Datenverarbeitung Verantwortliche, die die personenbezogenen
                Daten verarbeiten, darüber zu informieren, dass Sie von ihnen
                die Löschung aller Links zu diesen personenbezogenen Daten oder
                von Kopien oder Replikationen dieser personenbezogenen Daten
                verlangt hat.{' '}
              </p>
              <p>
                <strong>3.4 Recht auf Einschränkung der Verarbeitung</strong>
                <br /> <br /> Sie haben das Recht, von uns die Einschränkung der
                Verarbeitung zu verlangen, wenn eine der folgenden
                Voraussetzungen gegeben ist: die Richtigkeit der
                personenbezogenen Daten wird von Ihnen bestritten, und zwar für
                eine Dauer, die es uns ermöglicht, die Richtigkeit der
                personenbezogenen Daten zu überprüfen, die Verarbeitung
                unrechtmä&szlig;ig ist und Sie die Löschung der
                personenbezogenen Daten ablehntenund stattdessen die
                Einschränkung der Nutzung der personenbezogenen Daten verlangt;
                wir die personenbezogenen Daten für die Zwecke der Verarbeitung
                nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen benötigten, oder
                Sie Widerspruch gegen die Verarbeitung gemä&szlig; Artikel 21
                Absatz 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob
                die berechtigten Gründe unseres Unternehmens gegenüber den Ihren
                überwiegen.{' '}
              </p>
              <p>
                <strong>3.5 Recht auf Datenübertragbarkeit</strong>
                <br /> <br /> Sie haben das Recht, die Sie betreffenden
                personenbezogenen Daten, die uns bereitgestellt haben, in einem
                strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten, und Sie haben das Recht, diese Daten einem anderen
                Verantwortlichen ohne Behinderung durch uns zu übermitteln,
                sofern 1. die Verarbeitung auf einer Einwilligung gemä&szlig;
                Artikel 6 Absatz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2
                Buchstabe a DSGVO oder auf einem Vertrag gemä&szlig; Artikel 6
                Absatz 1 Buchstabe b DSGVO beruht und 2. die Verarbeitung
                mithilfe automatisierter Verfahren erfolgt. Bei der Ausübung
                ihres Rechts auf Datenübertragbarkeit gemä&szlig; Absatz 1 haben
                Sie das Recht, zu erwirken, dass die personenbezogenen Daten
                direkt von uns einem anderen Verantwortlichen übermittelt
                werden, soweit dies technisch machbar ist.{' '}
              </p>
              <p>
                <strong>3.6 Widerspruchsrecht</strong>
                <br /> <br /> Sie haben das Recht, aus Gründen, die sich aus
                Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung sie betreffender personenbezogener Daten, die
                aufgrund von Artikel 6 Absatz 1 Buchstaben e oder f DSGVO
                erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
                diese Bestimmungen gestütztes Profiling. Wir verarbeiten die
                personenbezogenen Daten nicht mehr, es sei denn, wir können
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
                die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
                Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen. Werden personenbezogene Daten
                von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie
                das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie
                betreffender personenbezogener Daten zum Zwecke derartiger
                Werbung einzulegen; dies gilt auch für das Profiling, soweit es
                mit solcher Direktwerbung in Verbindung steht. Sie haben das
                Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                ergeben, gegen die Sie betreffende Verarbeitung sie betreffender
                personenbezogener Daten, die zu wissenschaftlichen oder
                historischen Forschungszwecken oder zu statistischen Zwecken
                gemä&szlig; Artikel 89 Absatz 1 DSGVO erfolgt, Widerspruch
                einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung
                einer im öffentlichen Interesse liegenden Aufgabe erforderlich.{' '}
              </p>
              <p>
                <strong>
                  3.7 Automatisierte Entscheidungen einschlie&szlig;lich
                  Profiling
                </strong>
                <br /> <br /> Sie haben das Recht, nicht einer
                ausschlie&szlig;lich auf einer automatisierten Verarbeitung –
                einschlie&szlig;lich Profiling – beruhenden Entscheidung
                unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung
                entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.{' '}
              </p>
              <p>
                <strong>
                  3.8 Recht auf Widerruf einer datenschutzrechtlichen
                  Einwilligung
                </strong>
                <br /> <br /> Sie haben das Recht, eine Einwilligung zur
                Verarbeitung personenbezogener Daten jederzeit zu widerrufen{' '}
              </p>
              <p>
                <strong>
                  {' '}
                  3.9 Recht auf Beschwerde bei einer Aufsichtsbehörde
                </strong>
                <br /> <br /> Sie haben das Recht auf Beschwerde bei einer
                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                mutma&szlig;lichen Versto&szlig;es, Sie der Ansicht sind, dass
                die Verarbeitung der Sie betreffenden personenbezogenen Daten
                rechtswidrig{' '}
              </p>
              <p>
                <strong>4 Datensicherheit</strong>
                <br /> <br /> Wir sind um die Sicherheit Ihrer Daten im Rahmen
                der geltenden Datenschutzgesetze und technischen Möglichkeiten
                maximal bemüht. Ihre persönlichen Daten werden bei uns
                verschlüsselt übertragen. Dies gilt für Ihre Bestellungen und
                auch für das Kundenlogin. Wir nutzen das Codierungssystems SSL
                (Secure Socket Layer), weisen jedoch darauf hin, dass die
                Datenübertragung im Internet (z.B. bei der Kommunikation per
                E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
                der Daten vor dem Zugriff durch Dritte ist nicht möglich. Zur
                Sicherung Ihrer Daten unterhalten wir technische- und
                organisatorische Sicherungsma&szlig;nahmen, die wir immer wieder
                dem Stand der Technik anpassen. Wir gewährleisten au&szlig;erdem
                nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung
                steht; Störungen, Unterbrechungen oder Ausfälle können nicht
                ausgeschlossen werden. Die von uns verwendeten Server werden
                regelmä&szlig;ig sorgfältig gesichert.{' '}
              </p>
              <p>
                <strong>5 Automatisierte Entscheidungsfindung</strong>
                <br /> <br /> Eine automatisierte Entscheidungsfindung auf der
                Grundlage der erhobenen personenbezogenen Daten findet nicht
                statt.{' '}
              </p>
              <p>
                <strong>
                  6 Weitergabe von Daten an Dritte, Keine Datenübertragung ins
                  Nicht-EU-Ausland
                </strong>
                <br /> <br /> Grundsätzlich verwenden wir Ihre personenbezogenen
                Daten nur innerhalb unseres Unternehmens. Wenn und soweit wir
                Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa
                Logistik-Dienstleister) erhalten diese personenbezogene Daten
                nur in dem Umfang, in welchem die Übermittlung für die
                entsprechende Leistung erforderlich ist. Für den Fall, dass wir
                bestimmte Teile der Datenverarbeitung auslagern
                („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter
                vertraglich dazu, personenbezogene Daten nur im Einklang mit den
                Anforderungen der Datenschutzgesetze zu verwenden und den Schutz
                der Rechte der betroffenen Person zu gewährleisten. Eine
                Datenübertragung an Stellen oder Personen au&szlig;erhalb der EU
                au&szlig;erhalb der in dieser Erklärung in Punkt 2.3 genannten
                Fällen findet nicht statt und ist nicht geplant. Bereitgestellt
                durch: RA Maximilian Greger (www.law-blog.de){' '}
              </p>
            </div>
            <h2>
              Verarbeitung (personenbezogener) Daten durch den Betreiber der
              Mobile Funnel
            </h2>
            <p>
              <strong>Allgemeines</strong>
              <br /> <br /> Diese Website verwende Mobile Funnel (folgend:
              Funnel), betrieben von Perspective Software GmbH (folgend:
              Perspective), einem Unternehmen mit Sitz in Deutschland, welche
              eine Software zur Erstellung und zum Betrieb von Mobile Funnel
              anbietet (https://perspective.co/impressum). Die im Rahmen der
              Verwendung von Mobilen Funnel eingetragenen Daten werden per
              SSL-Verschlüsselung übertragen und in einer Datenbank gespeichert.
              Für diese Daten ist allein der Betreiber dieser Website
              verantwortlich im Sinne von Art. 24 DS-GVO. Perspective ist
              lediglich Betreiber der Software und in dem Zusammenhang
              Auftragsverarbeiter nach Art. 28 DS-GVO. Die Grundlage für die
              Verarbeitung durch Perspective ist hierbei ein Vertrag zur
              Auftragsverarbeitung zwischen der verantwortlichen Stelle und
              Perspective. Zudem verarbeitet die Perspective Software GmbH zur
              Erbringung ihrer Dienstleistungen, insbesondere für den Betrieb
              der Mobile Funnel, weitere Daten, die zum Teil auch
              personenbezogene Daten sein können. Darauf wird im Folgenden näher
              eingegangen.
            </p>
            <p>
              <strong>Verantwortliche Stelle</strong>
              <br /> <br /> Verantwortliche Stelle im Sinne des
              Datenschutzrechts ist:
              <br /> <br /> Perspective Software GmbH
              <br /> Müggelstraße 22
              <br /> 10247 Berlin
              <br /> E-Mail: privacy@perspective.co
            </p>
            <p>
              <strong>Zugriffsprotokolle („Server-Logs“)</strong>
              <br /> <br /> Mit jedem Zugriff auf die Funnel werden automatisch
              allgemeine Protokolldaten, sogenannte Server-Logs, erfasst. Diese
              Daten sind in der Regel Pseudonyme und erlauben daher keine
              Rückschlüsse auf eine natürliche Person. Ohne diese Daten wäre es
              technisch teilweise nicht möglich, die Inhalte der Software
              auszuliefern und darzustellen. Zudem ist die Verarbeitung dieser
              Daten aus Sicherheitsaspekten, insbesondere zur Zugriffs-,
              Eingabe- und Weitergabe- und Speicherkontrolle, zwingend
              notwendig. Darüber hinaus können die anonymen Informationen für
              statistische Zwecke sowie für die Optimierung des Angebots und der
              Technik verwendet werden. Zudem können die Log-Files bei Verdacht
              auf eine rechtswidrige Nutzung der Software nachträglich
              kontrolliert und ausgewertet werden. Die Rechtsgrundlage hierfür
              findet sich in § 15 Abs. 1 Telemediengesetz (TMG) sowie Art. 6.
              (1) f DS-GVO. Erfasst werden allgemein Daten wie der Domainname
              der Website, der Webbrowser und Webbrowser-Version, das
              Betriebssystem sowie der Zeitstempel des Zugriffs auf die
              Software. Die IP-Adresse des Nutzers wird nicht gespeichert. Dem
              Nutzer wird jedoch eine sog. Session ID zugewiesen. Der Umfang
              dieser Protokollierung geht nicht über den gängigen Umfang jeder
              anderen Webseite im Internet hinaus. Die Speicherdauer dieser
              Zugriffsprotokolle beträgt bis zu 7 Tage. Ein Widerspruchsrecht
              besteht nicht.
            </p>
            <p>
              <strong>Erhebung von User Behavior Daten</strong>
              <br /> <br /> Erfasst werden allgemein Daten wie der Domainname
              der Website, der Webbrowser und Webbrowser-Version, das
              Betriebssystem, die Session ID des Nutzers sowie der Zeitstempel
              des Zugriffs auf die Software. Alle Daten, die der Nutzer bei der
              Nutzung der Funnel eingibt (z.B. Beantwortung von
              Formular-Feldern; Nutzung von interaktiven Komponenten) werden dem
              Nutzer mittels einer Session ID zugewiesen und dem Betreiber
              dieser Website zur Verfügung gestellt. Dem Betreiber dieser
              Website obliegt die Löschung, Speicherung und Weiterverarbeitung
              dieser personenbezogenen Daten entsprechend der aktuellen
              Gesetzgebung.
            </p>
            <p>
              <strong>Einsatz von Cookies</strong>
              <br /> <br /> In den Funnel werden sogenannte Cookies eingesetzt.
              Dies sind kleine Textdateien, welche auf dem Gerät, mit welchem
              Sie auf diese Funnel zugreifen, gespeichert werden. Die
              eingesetzten Cookies dienen dazu, die Sicherheit beim Besuch einer
              Website zu gewährleisten („unbedingt erforderlich“), gewisse
              Funktionalitäten wie Standard-Spracheinstellungen umzusetzen
              („funktional“) und das Nutzungserlebnis oder die Performance auf
              der Webseite zu verbessern („Performance“). Innerhalb der Funnel
              kommen unbedingt erforderliche, funktionale und Performance
              Cookies zum Einsatz, insbesondere zur Umsetzung gewisser
              Voreinstellungen wie bspw. die Sprache, zur Speicherung von
              gegebenen Antworten auch bei schlechter Internetverbindung oder
              zur Analyse der Performance eines Funnel und des genutzten Kanal,
              über den ein Nutzer auf diese Funnel gelangt ist. Die Nutzung von
              Cookies ist für die Erbringung unserer Dienstleistungen und damit
              für die Erfüllung des Vertrags (Art. 6 (1) b) DS-GVO) zwingend
              erforderlich. Speicherdauer: Bis zu 1 Monat bzw. bis zur
              Beendigung der Browser-Session Widerspruchsrecht: Sie können über
              Ihre Browser-Einstellungen selbst bestimmen, ob Sie Cookies
              erlauben oder der Nutzung von Cookies widersprechen möchten. Bitte
              beachten Sie, dass eine Deaktivierung der Cookies zu einer
              eingeschränkten oder komplett unterbundenen Funktionalität der
              Funnel führen kann. Der Betreiber dieser Website hat die
              Möglichkeit individuell für jeden eingesetzten Funnel zu
              entscheiden, welche Entscheidungsmöglichkeiten zur Nutzung von
              Cookies dem Nutzer gegeben wird. Es ist im Rahmen der
              Verantwortung des Betreibers der Website, Cookies im Einklang mit
              den geltenden rechtlichen Beschränkungen zu nutzen. Werden von dem
              Betreiber der Website Erweiterungen externer Firmen innerhalb der
              Funnel eingesetzt, obliegt es der Verantwortung des Betreibers der
              Website, den Nutzer darüber aufzuklären.
            </p>
            <p>
              <strong>Betroffenenrechte</strong>
              <br /> <br /> Sofern durch die Perspective Software GmbH als
              verantwortliche Stelle personenbezogenen Daten verarbeitet werden,
              haben Sie als betroffene Person in Abhängigkeit von
              Rechtsgrundlage und Zweck der Verarbeitung bestimmte Rechte aus
              Kapitel III der EU Datenschutzgrundverordnung (DS-GVO), dabei ggf.
              insbesondere Recht auf Auskunft (Art.15 DS-GVO), Recht auf
              Berichtigung (Art.16 DS-GVO), Recht auf Löschung (Art. 17 DS-GVO),
              Recht auf Einschränkung der Verarbeitung (Art. 18 DS-GVO), Recht
              auf Datenübertragbarkeit (Art. 20 DS-GVO), Recht auf Widerspruch
              (Art. 21 DSGVO). Sofern die Verarbeitung von personenbezogenen
              Daten auf Ihrer Einwilligung beruht, haben sie nach Art. 7 III
              DS-GVO das Recht auf Widerruf dieser datenschutzrechtlichen
              Einwilligung. Bitte wenden Sie sich zur Geltendmachung Ihrer
              Betroffenenrechte in Bezug auf die für den Betrieb der Funnel
              verarbeiteten Daten an den Datenschutzbeauftragten der Perspective
              Software GmbH (siehe Ziffer B.).
            </p>
            <p>
              <strong>Abschließende Bestimmungen</strong>
              <br /> <br /> Perspective behält sich vor, diese
              Datenschutzerklärung jederzeit anzupassen, damit sie stets den
              aktuellen rechtlichen Anforderungen entspricht oder um Änderungen
              der Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei
              der Einführung neuer Services. Für einen erneuten Besuch der
              Funnel gilt dann die neue Datenschutzerklärung.
              <br /> <br /> Stand: 24.03.2021
            </p>
            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              <span className="highlight">Impressum</span> &amp;
              <span className="highlight">Datenschutzerklärung</span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

ImpressumDatenschutz.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

ImpressumDatenschutz.defaultProps = {
  reverse: false,
  image: IMAGE,
};

export default ImpressumDatenschutz;
