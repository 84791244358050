// //////////////////////////////
// IMPORT MODULES
import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './Footer.module.scss'; // eslint-disable-line

function Footer() {
  return (
    <div className={`${style['footer-hacker']}`}>
      <div className="container">
        Copyright&nbsp;©&nbsp;2013&nbsp;-&nbsp;2021&nbsp;Impact&nbsp;Horizon{' '}
        <Link to="/impressum-datenschutz">Impressum | Datenschutz</Link> |{' '}
        <Link to="/kontakt">Kontakt</Link>
      </div>
    </div>
  );
}

// Footer.propTypes = {

// };

// Footer.defaultProps = {

// };

export default Footer;
