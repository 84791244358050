// /* eslint-disable */
// //////////////////////////////
// IMPORT MODULES
import React from 'react';
import PropTypes from 'prop-types';
import Interweave from 'interweave';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS

// //////////////////////////////
// IMPORT IMAGES

// //////////////////////////////
// IMPORT STYLES
import style from './GridRender.module.scss';

function GridRender({
  heading,
  leftContent,
  leftCss,
  rightContent,
  rightCss,
  gray,
}) {
  return (
    <section
      className={`container ${style['grid-render']} ${gray &&
        style['grid-render--gray']}`}
    >
      <div className="grid--wrapper">
        <Interweave content={heading} />
        <div
          className={leftCss}
          // style={{ background: 'pink' }}
        >
          {typeof leftContent === 'object' ||
          typeof leftContent === 'symbol' ||
          typeof leftContent === 'function' ? (
            leftContent
          ) : (
            <Interweave content={leftContent} />
          )}
        </div>
        {/* leftContent */}

        <div
          className={rightCss}
          // style={{ background: 'green' }}
        >
          {typeof rightContent === 'object' ||
          typeof rightContent === 'symbol' ||
          typeof rightContent === 'function' ? (
            rightContent
          ) : (
            <Interweave content={rightContent} />
          )}
        </div>
        {/* rightContent */}
      </div>
      {/* grid--wrapper */}
    </section>
  );
}

GridRender.propTypes = {
  heading: PropTypes.node,
  rightContent: PropTypes.node.isRequired,
  rightCss: PropTypes.string.isRequired,
  leftContent: PropTypes.node.isRequired,
  leftCss: PropTypes.string.isRequired,
  gray: PropTypes.bool,
};

GridRender.defaultProps = {
  heading: null,
  gray: false,
};

export default GridRender;
