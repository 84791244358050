// //////////////////////////////
// IMPORT MODULES
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// //////////////////////////////
// IMPORT HIGHER ORDER COMPONENTS OR FUNCTIONS

// //////////////////////////////
// IMPORT COMPONENTS
import Header from '../Header';
import Footer from '../Footer';
// import Buttons from '../Buttons';

// //////////////////////////////
// IMPORT IMAGES
import IMAGE from '../../images/img_ansatz_optimized.jpg';

// //////////////////////////////
// IMPORT STYLES
import style from './AgenturHerangehensweise.module.scss';

function AgenturHerangehensweise({ image, reverse }) {
  return (
    <Fragment>
      <Header />
      <div className={`${style['view-type-one']}`}>
        <div className={`${style['view-type-one__wrapper']}`}>
          <section
            className={`${style['view-type-one__content-side']}
          ${reverse && style['view-type-one--reverse']}`}
          >
            <h1
              className={`${style['view-type-one__content-side__heading']}
          ${reverse && style['view-type-one--reverse']}`}
            >
              <span
                className={`${
                  style['view-type-one__content-side__heading--space-left']
                }`}
              >
                Unser
              </span>{' '}
              Ansatz &ndash; über den
              <span className="highlight">Horizont hinaus! </span>
            </h1>
            <h2>
              Ideenreiche Konzepter, unabhängige Sparringpartner, präzise
              Umsetzer: Für Sie stellen wir das Team zusammen, das Sie brauchen.
              Wir analysieren Ihre Herausforderung und bringen die Menschen an
              einen Tisch, die es für eine erfolgreiche, digitale Transformation
              braucht.
            </h2>

            <div className="two-columns">
              <h2>Unsere Methoden</h2>
              <p>
                Gemeinsam definieren wir zunächst Ihr Kernziel mit allen
                Funktionen. Dabei werden Ideen evaluiert und in das Konzept
                eingearbeitet. Im nächsten Schritt priorisieren wir einzelne
                Aspekte. Auf der Basis eines klar definierten
                Anforderungsprofils entstehen konkrete Lösungen, die Strukturen,
                Prozesse und Ihre unternehmerischen Optionen erweitern und Ihren
                Arbeitsalltag vereinfachen.
              </p>
              <p>
                <strong>Für Sie bedeutet das:</strong>
                <ul>
                  <li>Volle Kostenkontrolle</li>
                  <li>Transparenter Projektüberblick</li>
                  <li>Termingerechte Ziellieferungen</li>
                </ul>
              </p>
            </div>

            <div className="two-columns">
              <h2>User Experience &amp; HCI</h2>
              <p>
                Unsere Konzepter entwickeln User Experience (UX) und User
                Interfaces (UI) gemäß neuesten Standards der „Human-Computer
                Interaction (HCI)“.
              </p>
              <p>
                <strong>Der Effekt für Sie:</strong>
                <br />
                Ihre Mitarbeiterinnen und Mitarbeiter nutzen Computer und
                Software anschließend intuitiv und gerne.
              </p>
            </div>
            {/* two-columns */}
            <Footer />
          </section>
          {/* view-type-one__content-side */}

          <aside
            className={`${style['view-type-one__image-side']} ${reverse &&
              style['view-type-one--reverse']}`}
          >
            <h1 className={`${style['view-type-one__image-side__heading']}`}>
              Unser Ansatz &ndash; über den
              <span className="highlight">Horizont hinaus! </span>
            </h1>
            <div
              className={`${
                style['view-type-one__image-side__background-gradient']
              }`}
            />
            <img src={image} alt="Impact Horizon" />
          </aside>
          {/* view-type-one__image-side */}
        </div>
        {/* view-type-one__wrapper */}
      </div>
    </Fragment>
    // view-type-one
  );
}

AgenturHerangehensweise.propTypes = {
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

AgenturHerangehensweise.defaultProps = {
  reverse: false,
  image: IMAGE,
};

export default AgenturHerangehensweise;
